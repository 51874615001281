
.useFontAwesomeFamily input::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	font-family: 'Font Awesome 5 Pro' !important;
}  

.useFontAwesomeFamily:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	font-family: 'Font Awesome 5 Pro' !important;
}

.useFontAwesomeFamily::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	font-family: 'Font Awesome 5 Pro' !important;
}

.useFontAwesomeFamily:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	font-family: 'Font Awesome 5 Pro' !important;
}

.useFontAwesomeFamily::-ms-input-placeholder {
	/* Microsoft Edge */
	font-family: 'Font Awesome 5 Pro' !important;
}

.useFontAwesomeFamily::placeholder {
	/* Most modern browsers */
	font-family: 'Font Awesome 5 Pro' !important;
}

.ts-icon::before {
	font-family: 'Font Awesome 5 Pro';
	font-size: var(--font-size-md);
	font-style: normal;
}

.ts-icon.fontawe-icon::before {
	font-family: 'Font Awesome 5 Pro';
	font-size: var(--font-size-md);
	font-style: normal;
}

.ts-icon.fontawe-icon-blue::before {
	color: var(--title-color);
}

.ts-icon.fontawe-icon-red::before {
	color: var(--danger-color);
}

.ts-icon.fontawe-icon-yellow::before {
	color: var(--warning-color);
}

.ts-icon.fontawe-icon-green::before {
	color: var(--success-color);
}

.ts-icon.fontawe-icon-blue-dark::before {
	color: var(--dark-imperial-blue);
}

.ts-icon.fontawe-icon-dark-blue-grey::before {
	color: var(--dark-blue-grey);
}

.ts-icon {
	cursor: pointer;
}
