.ant-skeleton {
	display: table;
	width: 100%;
}

.ant-skeleton-header {
	display: table-cell;
	vertical-align: top;
	padding-right: 16px;
}

.ant-skeleton-header .ant-skeleton-avatar {
	display: inline-block;
	vertical-align: top;
	background: #f2f2f2;
	width: 32px;
	height: 32px;
	line-height: 32px;
}

.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
	border-radius: 50%;
}

.ant-skeleton-header .ant-skeleton-avatar-lg {
	width: 40px;
	height: 40px;
	line-height: 40px;
}

.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
	border-radius: 50%;
}

.ant-skeleton-header .ant-skeleton-avatar-sm {
	width: 24px;
	height: 24px;
	line-height: 24px;
}

.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
	border-radius: 50%;
}

.ant-skeleton-content {
	display: table-cell;
	vertical-align: top;
	width: 100%;
}

.ant-skeleton-content .ant-skeleton-title {
	margin-top: 16px;
	height: 40px;
	width: 100%;
	background: #f2f2f2;
}

.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
	margin-top: 24px;
}

.ant-skeleton-content .ant-skeleton-paragraph > li {
	height: 40px;
	background: #f2f2f2;
	list-style: none;
	width: 100%;
}

.ant-skeleton-content .ant-skeleton-paragraph > li:nth-child(3) {
	width: 94%;
}

.ant-skeleton-content .ant-skeleton-paragraph > li:nth-child(4) {
	width: 96%;
}

.ant-skeleton-content .ant-skeleton-paragraph > li + li {
	margin-top: 16px;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
	margin-top: 12px;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
	margin-top: 28px;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
	background: -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
	background: -webkit-linear-gradient(left, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
	background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
	-webkit-animation: ant-skeleton-loading 1.4s ease infinite;
	animation: ant-skeleton-loading 1.4s ease infinite;
	background-size: 400% 100%;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
	background: -webkit-gradient(linear, left top, right top, color-stop(25%, #f2f2f2), color-stop(37%, #e6e6e6), color-stop(63%, #f2f2f2));
	background: -webkit-linear-gradient(left, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
	background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
	-webkit-animation: ant-skeleton-loading 1.4s ease infinite;
	animation: ant-skeleton-loading 1.4s ease infinite;
	background-size: 400% 100%;
}
@-webkit-keyframes ant-skeleton-loading {

	0% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0 50%;
	}
}
@keyframes ant-skeleton-loading {

	0% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0 50%;
	}
}
