* {
	outline: none;
	font-family: Roboto;
}

/* UX BASE Palette */

:root {
	--cyan-progress: rgb(0, 195, 234);
	--dark-imperial-blue: #005075;
	--columbia-blue: #c9d9e8;
	--azure: #def0f7;
	--diamond: #b9e6f6;
	--white: #ffffff;
	--white-smoke: #f2f5f8;
	--azureish-white: #e4eaf0;
	--yankees-blue: #152935;
	--cadet: #5a6872;
	--quick-silver: #9da6ad;
	--silver-sand: #c1c1c4;
	--gainsboro: #d8d8d9;
	--ghost-white: #fafbfd;
	--la-salle-green: #09822a;
	--crayola: #fdb927;
	--maximum-red: #d82829;
	--prune: #711423;
	--blue-sapphire: #0d6c80;
	--maximum-purple: #753f83;
	--islamic-green: #00aa00;
	--medium-slate-blue: #7745ec;
	--rusty-red: #da2c38;
	--royal-fuchsia: #c53090;
	--russian-violet: #361d5b;
	--ogre-odor: #fc4e3d;
	--cerulean: #0090d1;

	/* UX colors and variabiles */

	--font-size: 14px;
	--font-size-header: 20px;
	--font-size-xxs: 0.7em;
	--font-size-xs: 0.8em;
	--font-size-sm: 0.9em;
	--font-size-md: 1em;
	--font-size-lg: 1.1em;
	--font-size-xl: 1.2em;
	--font-size-xxl: 1.3em;
	--font-weight: 500;
	--primary-color: var(--cerulean);
	--primary-color-dark: var(--dark-imperial-blue);
	--primary-color2: var(--cyan-progress);
	--pale-grey: var(--white-smoke);
	--btn-color-disabled: var(--columbia-blue);
	--dark-blue-grey: var( --yankees-blue);
	--danger-color: var(--maximum-red);
	--warning-color: var(--crayola);
	--success-color: var(--islamic-green);
	--title-color: var(--primary-color);
	--primary-background: linear-gradient(-26.56505117707799deg, var(--primary-color2) 0%, var(--primary-color) 100%);
	--font-family: Roboto;
	--font-family-header: Cairo;
	--font-family-header-label: Cairo;
	/* page title */
	/* --font-family-header-title: Cairo-Regular; */

	--default-max-page-width: 1600px;
	--max-page-width: 1600px;
	--page-width: calc(100% - 60px);

	/* heights */
	--small-height: 32px;
	--normal-height: 40px;
	--large-height: 48px;	

	/* font size */
	--small-font-size: 12px;
	--normal-font-size: 14px;
	--large-font-size: 16px;
	--x-large-font-size: 18px;
	--xx-large-font-size: 20px;
	--xxx-large-font-size: 22px;
	--xxxx-large-font-size: 24px;

	/* input  */
	--input-backgroud: var(--pale-grey);
	--input-disabled-backgroud: var(--ghost-white);
	--input-color: var(--yankees-blue);
	--input-height: var(--normal-height);
	--input-addon-backgroud: var(--white);
	--input-color-disabled: var(--silver-sand);

	/* border colors */
	--input-addoncolor: var(--cadet);
	--input-bordercolor: var(--columbia-blue);
	--input-warnig-border: var(--warning-color);
	--input-danger-border: var(--danger-color);
	--input-bordercolor-active: var(--primary-color);
	--input-bordercolor-disabled: var(--silver-sand);
	--input-opacity-disabled: 0.64;

	/* border style */
	--input-border-width: 1px;
	--input-border-radius: 0;
	--input-border-style: solid;

	--input-line-height: 40px;
	--input-help-text-color: var(--silver-sand);

	--input-highlighted: var(--diamond);
	--input-backgroud-hover: var(--azure);
	--input-value: var(--quick-silver);

	/* ts-form-field */
	--label-color: var(--yankees-blue);
	--label-color-disabled: var(--quick-silver);

	/* panel */
	--panel-collapsed-backgroud: var(--pale-grey);

	/* messages */
	--message-background: var(--white);

	/* right panel */
	--right-panel-header-height: 48px;

	/* toolbar height */
	--toolbar-height: 48px;

	/* button height */
	--button-height: 32px;

}

/* .ts-content {
  height: 100%;
  background-color: white;
  margin: auto;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);    
  margin-bottom: 40px;
  padding: 15px;
} */

.ant-layout {
	height: 100%;
}

#root,
.ts-app,
.ts-layout {
	height: 100%;
	background: var(--white-smoke);
	flex: auto;
	align-items: center;
	margin: auto;
}

.ts-form .ts-field-wrapper,
.ts-crud-provider .ts-field-wrapper {
	margin-bottom: 16px;
}

.ts-box-separator-1,
.ts-box-separator-2,
.ts-box-separator-3,
.ts-box-separator-4,
.ts-box-separator-5,
.ts-box-separator-6 {
	background-color: var(--columbia-blue);
	width: 1px;
	margin: 0 auto;
	margin-top: 10px;
}

.ts-box-separator-6 {
	height: 530px;
}

.ts-box-separator-5 {
	height: 450px;
}

.ts-box-separator-4 {
	height: 320px;
}

.ts-box-separator-3 {
	height: 230px;
}

.ts-box-separator-2 {
	height: 160px;
}

.ts-box-separator-1 {
	height: 80px;
}

/* separatore linea */

.ts-box-line-separator {
	border-bottom: 1px solid var(--columbia-blue);
	border-bottom-width: 1px;
	border-bottom-style: solid;
	width: 100%;
	margin-top: 10px;
}

.ts-fields-group {
	padding: 20px 30px;
}

.ts-landing {
	padding: 0px 20px;
}

/* scrollbar Mac */

::-webkit-scrollbar {
	width: 9px;
	height: 9px;
}

::-webkit-scrollbar-track {
	-webkit-border-radius: 9px;
	border-radius: 9px;
	background: rgba(0,0,0,0.1);
}

::-webkit-scrollbar-thumb {
	-webkit-border-radius: 9px;
	border-radius: 9px;
	background: rgba(0,0,0,0.2);
}

::-webkit-scrollbar-thumb:hover {
	background: rgba(0,0,0,0.4);
}

::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(0,0,0,0.05);
}
