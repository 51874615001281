.ts-list .ant-empty-image {
	display: none;
}

.ts-list .ant-empty-description {
	display: none;
}

.ts-list .ant-list-split .ant-list-item {
	border-bottom: 1px solid var(--columbia-blue);
}