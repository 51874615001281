@-webkit-keyframes CheckboxEffect {

	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0.5;
	}

	100% {
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
		opacity: 0;
	}
}

@keyframes CheckboxEffect {

	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 0.5;
	}

	100% {
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
		opacity: 0;
	}
}

@-webkit-keyframes CheckboxEffectInner {

	0% {
		-webkit-transform: rotate(45deg) scale(0.5) translate(90%, -25%);
		transform: rotate(45deg) scale(0.5)translate(90%, -25%);
		opacity: 0.5;
	}

	50% {
		-webkit-transform: rotate(45deg) scale(1.2) translate(90%, -25%);
		transform: rotate(45deg) scale(1.2) translate(90%, -25%);
		opacity: 0.5;
	}

	100% {
		-webkit-transform: rotate(45deg) translate(90%, -25%);
		transform: rotate(45deg) translate(90%, -25%);
		opacity: 0;
	}
}

@keyframes CheckboxEffectInner {

	0% {
		-webkit-transform: rotate(45deg) scale(0.5) translate(90%, -25%);
		transform: rotate(45deg) scale(0.5) translate(90%, -25%);
		opacity: 0.5;
	}

	50% {
		-webkit-transform: rotate(45deg) scale(1.2) translate(90%, -25%);
		transform: rotate(45deg) scale(1.2) translate(90%, -25%);
		opacity: 0.5;
	}

	100% {
		-webkit-transform: rotate(45deg) translate(90%, -25%);
		transform: rotate(45deg) translate(90%, -25%);
	}
}

.ts-checkbox {
	height: var(--normal-height);
	display: inline-block;
	margin: 0;
	padding: 0;
}

.ts-checkbox .checkbox {
	position: relative;
	display: inline-block;
	min-width: 18px;
	height: 18px;
}

.ts-checkbox .checkbox input {
	opacity: 0;
	width: 0;
	height: 0;
}

.ts-checkbox .check {
	position: absolute;
	margin-top: 2px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 16px;
	height: 16px;
	border: solid 1px transparent;
	border-radius: 2px;
	background-image: linear-gradient(#fff, #fff), radial-gradient(circle at top left, var(--primary-color2), var(--primary-color));
	background-origin: border-box;
	background-clip: content-box, border-box;
}

.ts-checkbox .checkbox:not(.disabled-checkbox) {
	cursor: pointer;
}

.ts-checkbox .checkbox.disabled-checkbox > span.check {
	cursor: not-allowed;
	width: 16px;
	height: 16px;
	object-fit: contain;
	border-radius: 2px;
	border: solid 0.2vmin var(--columbia-blue);
	background-color: var(--white);
}


.ts-checkbox input:checked + .check {
	background-color: var(--primary-color);
	border: solid 0.2vmin linear-gradient(325deg, var(--primary-color2), var(--primary-color));
	border-radius: 2px;
	background-image: linear-gradient(325deg, var(--primary-color2), var(--primary-color));
}

.ts-checkbox .check:before {
	position: absolute;
	content: '';
}

.ts-checkbox input:checked + .check:before {
	position: absolute;
	width: 5.3px;
	height: 9.7px;
	left: 0px;
	border-top-width: 0px;
	border-right-color: var(--white);
	border-right-style: solid;
	border-right-width: 1.3px;
	border-bottom-color: var(--white);
	border-bottom-style: solid;
	border-bottom-width: 1.3px;
	border-left-width: 0px;
	box-shadow: 0.5px 0.5px 0.5px rgba(21, 41, 53, 0.24);
	-webkit-transform: rotate(45deg) translate(90%, -25%);
	-ms-transform: rotate(45deg) translate(90%, -25%);
	transform: rotate(45deg) translate(90%, -25%);
	-webkit-animation: CheckboxEffectInner 0.36s ease-in-out;
	animation: CheckboxEffectInner 0.36s ease-in-out;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	content: '';
}


.ts-checkbox .checkbox.disabled-checkbox> input:checked+ .check {
	background-color: var(--columbia-blue);
	background-image: var(--columbia-blue);
}

.ts-checkbox input:checked + .check::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid var(--columbia-blue);
	border-radius: 2px;
	-webkit-animation: CheckboxEffect 0.36s ease-in-out;
	animation: CheckboxEffect 0.36s ease-in-out;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	content: '';
}

.ts-checkbox .checklabel {
	display: inline-block;
	margin-left: 20px;
	min-height: 18px;
	font-family: Roboto;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.29;
	letter-spacing: normal;
	color: var(--dark-blue-grey);
}

.ts-checkbox .checkbox.disabled-checkbox > span.checklabel {
	height: 18px;
	font-family: Roboto;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.29;
	letter-spacing: normal;
	color: var(--quick-silver);
}