.ts-spin {
	position: relative;
}

.ts-spin .ts-spin-content-loading {
	position: fixed;
	z-index: 99;
	top: 35%;
	width: 100%;
	text-align: center;
}

.ts-spin .ts-spin-content-loading.nested {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.ts-spin .fa-3x,
.ts-spin .fa-2x,
.ts-spin .fa-4x {
	display: inline-block;
}

.ts-spin .fas.fa-circle-notch.fa-spin {
	color: var(--cerulean);
}

.ts-spin .fas.fa-circle-notch.fa-spin.default {
	animation: fa-spin 1.5s infinite linear;
	-webkit-animation: fa-spin 1.2s infinite linear;
}

.ts-spin .fas.fa-circle-notch.fa-spin.slow {
	animation: fa-spin 1.5s infinite linear;
	-webkit-animation: fa-spin 2s infinite linear;
}

.ts-spin .fas.fa-circle-notch.fa-spin.fast {
	animation: fa-spin 1.5s infinite linear;
	-webkit-animation: fa-spin 1s infinite linear;
}

.ts-spin .ts-spin-container {
	position: relative;
	opacity: 1;
	transition: opacity 0.5s;
}

.ts-spin .ts-spin-container.blur {
	clear: both;
	overflow: hidden;
	opacity: 0.4;
	user-select: none;
	pointer-events: none;
	transition: opacity 0.5s;
}

.ts-spin .ts-spin-bottomLabel {
	font-family: var(--font-family);
	font-size: var(--normal-font-size);
	color: var(--label-color);
	font-weight: 400;
}