.ant-notification  {
	width: auto;
	max-width: 1340px;
	z-index: 2100;
}

.ant-notification.ant-notification-topRight {
	margin-right: 12px;
}

.ant-notification > span {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.ant-notification-notice-closable 
.ant-notification-notice-message {
	padding-right: 0px;
	margin-bottom: 0px;
}

.ant-notification-notice {
	padding: 0px;
	border-radius: 0;
}

.ts-message.ant-notification-notice {
	width: fit-content;
}

.ts-message .ant-notification-notice-close {
	display: none;
}

.ts-message-title {
	font-weight: 500;
}

.ts-message-text-title{
	margin-bottom: 22px;
}

.ts-message-text{
	text-align: justify;
	margin-left: 5px;
}

.ts-message-hyperlink {
	text-decoration: underline;
	color: var(--primary-color);
	margin-left: 4px;
}

.ts-message-hyperlink:hover {
	text-decoration: underline;
	color: var(--primary-color);
}

.ts-message-info {
	border: solid 1px var(--primary-color-dark);
	border-bottom: solid 4px var(--primary-color-dark);
}

.ts-message-icon-info {
	font-size: 24px;
	color: var(--primary-color-dark);
}

.ts-message-icon-times {
	font-size: 16px;
	color: var(--primary-color-dark);
}

.ts-message-success {
	border: solid 1px var(--success-color);
	border-bottom: solid 4px var(--success-color);
}

.ts-message-icon-success {
	font-size: 24px;
	color: var(--success-color);
}

.ts-message-error {
	border: solid 1px var(--danger-color);
	border-bottom: solid 4px var(--danger-color);
}

.ts-message-icon-error {
	font-size: 24px;
	color: var(--danger-color);
}

.ts-message-warning {
	border: solid 1px var(--warning-color);
	border-bottom: solid 4px var(--warning-color);
}

.ts-message-icon-warning {
	font-size: 24px;
	color: var(--warning-color);
}

.ts-message-button {
	margin-top: 24px;
	display: block;
}

.ts-message-button-center {
	display: flex;
}

.ts-message-button-primary {
	float: right;
	margin: 0 6px 22px 6px;
}

.ts-message-button-normal1 {
	float: right;
	margin: 0 6px 22px 6px;
}

.ts-message-button-normal2 {
	float: left;
	margin: 0 6px 22px 6px;
}

.ts-message-button-primary-center {
	float: right;
	width: 50%;
	text-align: left;
	margin: 0 6px 22px 6px;
}

.ts-message-button-normal1-center {
	float: left;
	width: 50%;
	text-align: right;
	margin: 0 6px 22px 6px;
}

.ts-message  {
	display: flex;
}

.ts-message-center {
	margin-top: 22px;
	flex-direction: column;
}

.ts-message-icon {
	flex-direction: column;
	min-width: 64px; 
	max-width: 64px;
	text-align: center;
	padding-top: 20px;
}

.ts-message-icon-close {
	flex-direction: column;
	min-width: 56px; 
	max-width: 56px;
	text-align: center;
	padding-top: 20px;
}