.ts-pageErrors .ts-pageErrors-item {
	width: 100%;
	max-height: 40px;
}

.ts-pageErrors .ts-pageErrors-item-btn {
	display: inline-block;
	width: 85px;
	height: 100%;
	align-items: center;
}

.ts-pageErrors .ts-pageErrors-item-descr {
	display: inline-block;
	width: calc(100% - 85px);
	height: 100%;
	align-items: center;
	word-break: break-all;
}

.ts-pageErrors span {
	white-space: pre;
}