.ts-steps {
	display: flex;
}

.ts-steps .ts-step,
.ts-steps .ts-step-current,
.ts-steps .ts-step-disabled,
.ts-steps .ts-step-done {
	width: 32px;
	height: 32px;
	border-radius: 100%;
	font-family: Cairo;
	font-size: 16px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	margin-left: 36px;
}

.ts-steps .ts-step:hover,
.ts-steps .ts-step-done:hover,
.ts-steps .ts-step-current:hover {
	cursor: pointer;
}

.ts-steps .ts-step-done:hover {
	background-color: var(--cyan-progress);
}

.ts-steps .ts-step {
	border: solid 2px var(--cerulean);
	background-color: var(--whitesmoke);
	color: var(--cerulean);
	position: relative;
}

.ts-steps .ts-step-current {
	background-image: linear-gradient(343deg, var(--cyan-progress), var(--cerulean));
	text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
	color: var(--white);
	position: relative;
}

.ts-steps .ts-step-done {
	background-color: var(--columbia-blue);
	color: var(--white);
	position: relative;
}

.ts-step-disabled {
	border: solid 2px var(--columbia-blue);
	background-color: var(--white);
	color: var(--columbia-blue);
}

.ts-steps .ts-step-item {
	position: relative;
	display: inline-block;
	vertical-align: top;
	-ms-flex: 1;
	flex: 1 1;
	overflow: visible;
}

.ts-steps .ts-step-item:last-child {
	flex: none;
}

.ts-steps .ts-step-item-tail-dot,
.ts-steps .ts-step-item-tail-disabled,
.ts-steps .ts-step-item-tail {
	position: absolute;
	left: 0;
	width: 100%;
	top: 4px;
	margin-left: 48px;
	padding-left: 20px;
	padding-right: 10px;
}

.ts-steps .ts-step-item-tail-dot::after,
.ts-steps .ts-step-item-tail-disabled::after,
.ts-steps .ts-step-item-tail::after {
	content: '';
	display: inline-block;
	height: 2px;
	border-radius: 1px;
	width: 100%;
}

.ts-steps .ts-step-item-tail::after {
	background-color: var(--cerulean);
}

.ts-steps .ts-step-item-tail-disabled::after {
	background-color: var(--columbia-blue);
}

.ts-steps .ts-step-item-tail-dot::after {
	content: '...........';
	color: var(--columbia-blue);
}

.ts-steps .ts-step-item-title {
	display: block;
	margin-top: 8px;
	width: 100px;
	height: 32px;
	font-family: Cairo;
	font-size: 14px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.14;
	letter-spacing: normal;
	text-align: center;
	color: var(--dark-imperial-blue);
}

.ts-steps .ts-step-key {
	padding-top: 5px;
}

.ts-steps .ts-step-key-current {
	padding-top: 7px;
}
