/* Buttons */

.ts-button,
.ts-button > button > div {
	display: inline-block;
}

.ts-button > .ant-btn,
.ts-modal .ant-btn {
	font-weight: 500;
	border-radius: 2px;
}

/* Non utilizzati per ora
.ts-button > .ant-btn-dashed,
.ts-modal .ant-btn-dashed {
	border-style: dashed;
}

.ts-button > .ant-btn-ghost,
.ts-modal .ant-btn-ghost {
	color: var(--title-color);
	border: none;
} */

.ts-button > .ant-btn[disabled],
.ts-button > .ant-btn-primary[disabled],
.ts-button > .ant-btn-default[disabled],
.ts-button > .ant-btn-link[disabled],
.ts-button > .ant-btn-danger[disabled],
.ts-modal > .ant-btn[disabled],
.ts-modal > .ant-btn-primary[disabled],
.ts-modal > .ant-btn-default[disabled],
.ts-modal > .ant-btn-link[disabled],
.ts-modal > .ant-btn-danger[disabled] {
	cursor: not-allowed;
}

.ts-button .ant-btn-circle {
	border-radius: 50%;
}

.ts-button button.ant-btn-primary,
.ts-modal button.ant-btn-primary {
	border: transparent;
	color: var(--white);
	background-image: var(--primary-background);
}

.ts-button button.ant-btn-primary:hover,
.ts-modal button.ant-btn-primary:hover {
	background-position: 0 -100%;
	background-color: var(--primary-color2);
	background-image: none;
}

.ts-button button.ant-btn-primary:disabled,
.ts-modal button.ant-btn-primary:disabled {
	border: transparent;
	color: var(--white);
	background-color: var(--btn-color-disabled);
	background-image: none;
	border: solid 1px var(--btn-color-disabled);
}

.ts-button button.ant-btn.ant-btn-default,
.ts-modal button.ant-btn.ant-btn-default {
	border: 1px solid var(--primary-color);
	color: var(--primary-color);
	background: none;
}

.ts-button button.ant-btn.ant-btn-default:hover,
.ts-modal button.ant-btn.ant-btn-default:hover {
	background: var(--primary-color);
	color: var(--white);
}

.ts-button button.ant-btn.ant-btn-default:active,
.ts-modal button.ant-btn.ant-btn-default:active {
	background-color: var(--primary-color-dark);
	color: var(--white);
	border-color: var(--primary-color-dark);
}

.ts-button button.ant-btn.ant-btn-default:disabled,
.ts-modal button.ant-btn.ant-btn-default:disabled,
.ts-button button.ant-btn.danger.ant-btn-default:disabled,
.ts-modal button.ant-btn.danger.ant-btn-default:disabled {
	background-color: var(--white);
	border-color: var(--btn-color-disabled);
	color: var(--btn-color-disabled);
}

.ts-button button.ant-btn.ant-btn-link,
.ts-modal button.ant-btn.ant-btn-link {
	color: var(--primary-color);
}

.ts-button button.ant-btn.ant-btn-link:hover:not(:disabled),
.ts-modal button.ant-btn.ant-btn-link:hover:not(:disabled) {
	background-color: var(--white-smoke);
}

.ts-button button.ant-btn.ant-btn-link:active,
.ts-modal button.ant-btn.ant-btn-link:active {
	background-color: var(--white-smoke);
	color: var(--primary-color-dark);
}

.ts-button button.ant-btn.ant-btn-link:disabled,
.ts-modal button.ant-btn.ant-btn-link:disabled,
.ts-button button.ant-btn.danger.ant-btn-link:disabled,
.ts-modal button.ant-btn.danger.ant-btn-link:disabled {
	color: var(--btn-color-disabled);
}

.ts-button button.ant-btn-danger,
.ts-modal button.ant-btn-danger {
	border-color: var(--danger-color);
	background-color: var(--danger-color);
}

.ts-button button.ant-btn-danger:hover,
.ts-modal button.ant-btn-danger:hover {
	background-color: var(--ogre-odor);
	border-color: var(--ogre-odor);
}

.ts-button button.ant-btn-danger:active,
.ts-modal button.ant-btn-danger:active {
	border-color: var(--danger-color);
	background-color: var(--danger-color);
}

.ts-button button.ant-btn-danger:disabled,
.ts-modal button.ant-btn-danger:disabled {
	color: var(--white);
	border-color: var(--btn-color-disabled);
	background-color: var(--btn-color-disabled);
}

.ts-button button.ant-btn.danger.ant-btn-default,
.ts-modal button.ant-btn.danger.ant-btn-default {
	border-color: var(--danger-color);
	color: var(--danger-color);
}

.ts-button button.ant-btn.danger.ant-btn-default:hover:not(:disabled),
.ts-modal button.ant-btn.danger.ant-btn-default:hover:not(:disabled) {
	background-color: var(--ogre-odor);
	border-color: var(--ogre-odor);
	color: var(--white);
}

.ts-button button.ant-btn.danger.ant-btn-default:active,
.ts-modal button.ant-btn.danger.ant-btn-default:active,
.ts-button button.ant-btn.danger.ant-btn-link:active,
.ts-modal button.ant-btn.danger.ant-btn-link:active {
	border-color: var(--danger-color) !important;
	background-color: var(--danger-color) !important;
	color: var(--white) !important;
}

.ts-button button.ant-btn.danger.ant-btn-link,
.ts-modal button.ant-btn.danger.ant-btn-link {
	color: var(--danger-color);
}

.ts-button button.ant-btn.danger.ant-btn-link:hover:not(:disabled),
.ts-modal button.ant-btn.danger.ant-btn-link:hover:not(:disabled) {
	background-color: var(--ogre-odor);
	border-color: var(--ogre-odor);
	color: var(--white);
}