.ts-breadcrumb-title,
.ts-breadcrumb-title a.ant-breadcrumb-link,
.ts-breadcrumb-title span.ant-breadcrumb-separator,
.ts-breadcrumb-title span.ant-breadcrumb-link,
.ts-breadcrumb-title span.ant-breadcrumb-link > span,
.ts-breadcrumb-title span.ant-breadcrumb-link > a {
	font-size: 14px;
	/* color: var(--primary-color-dark); */
	font-family: var(--font-family-header);
	/* font-weight: normal; */
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
}

.ts-breadcrumb-title span.ant-breadcrumb-link .ts-breadcrumb-item-href {
	font-weight: 400;
	color: var(--cerulean);
}

.ts-breadcrumb-title span.ant-breadcrumb-link > span{
	font-weight: 700;
	font-style: bold;
	color: var(--dark-imperial-blue);
}
