.ts-picker {
	position: relative;
	height: 40px;
	width: 100%;
}

.ts-picker > div:first {
	position: absolute;
	width: 100%;
	height: 100%;
}

.ts-picker .ts-input .ant-input-group {
	display: block;
}   

.ts-picker .ts-input input.ant-input {
	padding-right: 40px;
}

.ts-picker .ts-input .ant-input-wrapper.ant-input-group .ant-input-group-addon {
	position: absolute;
	top: 0;
	right: 0;
	height: 40px;
	width: 40px;
	background-color: transparent;
	border: none;
	z-index: 1;
	display: flex;
	align-items: center;
}

.ts-picker > .ts-picker-datetime,
.ts-picker > .ts-picker-time,
.ts-picker > .ts-picker-month {
	z-index: -1;
	position: absolute;
	height: 100%;
	opacity: 0;
	visibility: hidden;
}

.ts-picker > .ts-picker-datetime,
.ts-picker > .ts-picker-month {
	right: 110px;
}

.ts-datepicker > .ts-picker-time {
	right: 45px;
}

.ts-picker-widget .ant-time-picker-panel-input-wrap,
.ts-picker-widget .ant-calendar-input-wrap {
	display: none;
}

/* disabled */

.ts-picker.disabled .ts-input .ant-input-wrapper.ant-input-group > input,
.ts-picker.disabled .ts-input .ant-input-wrapper.ant-input-group > input:hover,
.ts-picker.disabled .ts-input .ant-input-wrapper.ant-input-group > input:focus,
.ts-picker.disabled .ts-input .ant-input-wrapper.ant-input-group .ant-input-group-addon {
	border-color: var(--input-bordercolor-disabled);
}

.ts-picker.disabled .ts-input .ant-input-wrapper.ant-input-group > input {
	color: var(--input-color-disabled);
	opacity: var(--input-opacity-disabled);
	background-color: var(--input-disabled-backgroud);
}

.ts-picker.disabled .ts-input .ant-input-wrapper.ant-input-group .ant-input-group-addon {
	color: var(--input-color-disabled);
}

.ts-picker.disabled .ts-input .ant-input-wrapper.ant-input-group .ant-input-group-addon .ts-icon {
	cursor: not-allowed;
}