.ts-tabs .ant-tabs-tab {
	color: var(--cadet);
	font-size: var(--font-size);
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
}

.ts-tabs .ant-tabs-tab-active.ant-tabs-tab,
.ts-tabs.large .ant-tabs-tab-active.ant-tabs-tab {
	border-bottom: 3px solid var(--cerulean);
}

.ts-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-no-animated,
.ts-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
	background-color: var(--cerulean);
}

.ts-tabs.ant-tabs-default .ant-tabs-bar,
.ts-tabs.ant-tabs-large .ant-tabs-bar  {
	height: 70px;
}

.ts-tabs.ant-tabs-small .ant-tabs-bar {
	height: 48px;
}

.ts-tabs .ant-tabs-nav .ant-tabs-tab {
	-webkit-transition: none;
	transition: none;
	margin: 0;
}

.ts-tabs .ant-tabs-tab-active.ant-tabs-tab,
.ts-tabs .ant-tabs-nav .ant-tabs-tab-active {
	color: var(--cerulean);
	background-color: var(--white-smoke);
}

.ts-tabs .ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-disabled):hover {
	background-color: var(--cerulean);
	color: var(--white);
}

.ts-tabs .ant-tabs-nav .ant-tabs-tab-disabled {
	color: var(--silver-sand);
}

.ts-tabs .ant-tabs-bar *:not(.ant-tabs-ink-bar) {
	height: 100%;
}

.ts-tabs .ts-tab-title {
	height: 100%;
	display: flex;
	align-items: center;
	font-weight: 500;
}

.ts-tabs .ts-tab-title > div {
	font-family: var(--font-family-header-label);
	font-weight: 600;
}

.ts-tab-title.large {
	font-size: var(--xxx-large-font-size);
}

.ts-tabs.large .ant-tabs-tab-active.ant-tabs-tab,
.ts-tabs.large .ant-tabs-tab {
	padding-left: 32px;
	padding-right: 32px;
}

.ts-tab-title > div:first-child {
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	float: left;
	height: 31px;
}

.ts-tab-title .ts-tab-close {
	display: flex;
	align-items: center;
	width: 24px;
	float: right;
	position: relative;
}

.ts-tab-title .ts-tab-close > div {
	height: 16px;
	width: 100%;
	right: -5px;
	top: 8px;
	position: absolute;
}
