.ts-table-checkbox-container .ts-table-checkbox {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ts-table .ts-checkbox-table .ts-expanded-component {
	padding-left: 80px;
}

.ts-table .ts-checkbox-table.ReactTable .rt-thead .rt-th.ts-table-multi-header:nth-child(n+3):not(.-cursor-pointer) {
	box-shadow: -1px 0 0 0 var(--columbia-blue);
}

.ts-table .ts-checkbox-table .rt-td.ts-checkbox-table-selector.ts-cell-before-column-fixed {
	box-shadow: inset -8px 0 8px -8px rgb(157, 166, 173);
}