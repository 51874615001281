.ts-textarea textarea {
	font-size: var(--normal-font-size);
	background: inherit;
	background-color: var(--input-backgroud);
	box-sizing: border-box ;
	border-width: var(--input-border-width) ;
	border-style: var(--input-border-style) ;
	border-radius: var(--input-border-radius) ;
	border-color: var(--input-bordercolor) ;
	color: var(--input-color);
}

.ts-textarea textarea.ant-input:hover,
.ts-textarea textarea.ant-input:focus {
	box-shadow: 1px 1px transparent; 
	border-color: var(--input-bordercolor-active);
	background-color: var(--input-backgroud);
} 

/* disabled */
/* .ts-textarea.disabled textarea:hover,
.ts-textarea textarea:focus {
  border-color: var(--input-bordercolor-disabled);
} */

.ts-textarea textarea.ant-input.ant-input-disabled {
	/* border-color: var(--input-bordercolor-disabled) !important; */
	border-color: var(--input-bordercolor-disabled);
	color: var(--input-color-disabled);
	opacity: var(--input-opacity-disabled);
	background-color: var(--input-disabled-backgroud);
}

.ts-textarea.readonly {
	border: 1px solid var(--silver-sand);
	background-color: var(--ghost-white);
	border-radius: 0;
}

.ts-textarea.readonly > textarea {
	background-color: transparent;
	border: none;
	resize: none;
}

.ts-textarea.readonly > textarea:hover,
.ts-textarea.readonly > textarea:focus {
	background-color: transparent;
}
