.ts-label {
	font-family: var(--font-family);
	font-size: var(--normal-font-size);
	color: var(--label-color);
	font-weight: 400;
}

label.ts-title.ts-label {
	color: var(--primary-color-dark);    
	display: inline-block;;
	font-size: var(--large-font-size);    
	margin-bottom: 10px;
	height: 28px;
	font-family: var(--font-family-header-label);
	/* font-size: 16px; */
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	margin-bottom: 16px;
}

label.ts-label.ts-input-label {
	/* da utilizzare nelle label dei campi */
	color: var(--input-color);
	font-weight: 500;
	padding: 3px 0 12px 0;
	display: block;
}

/* regole per label per parametri in output */

.ts-label-param {
	height: 18px;
	font-family: var(--font-family);
	font-size: var(--normal-font-size);
	font-weight: 500;
	width: 80%;
	font-style: normal;
	font-stretch: normal;
	/* line-height: 1.29;   */
	letter-spacing: normal;
	color: var(--label-color);
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 2px;
}

/* label valori parametri */

.ts-label-param-value {
	margin-bottom: 12px;
	min-height: 21px;
	color: var(--yankees-blue);
	display: block;
}