/* .ts-collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 15px 0px 40px;
} */

.ts-collapse .ant-collapse-header {
	background-color: var(--panel-collapsed-backgroud);
	border-color: transparent;
	border-width: 1px;
	border-style: solid;    
}

.ts-collapse .ant-collapse-header .ts-label {
	color: var(--primary-color-dark);
	font-family: var(--font-family-header-label);
	font-weight: 600;
}

.ts-collapse .ant-collapse-header .arrow {
	color: var(--primary-color-dark);
	font-weight: 500;
} 

.ts-collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > i {
	color: var(--primary-color-dark);
}

.ts-collapse .ant-collapse-header:hover {
	border-color: var(--primary-color);
	border-width: 1px;
	border-style: solid;
}

.ts-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
	background-color: transparent;
}

.ts-collapse .ant-collapse-item.ant-collapse-item-active .ant-collapse-header:hover {
	background-color: transparent;
	border-color: var(--primary-color);
	border-width: 1px;
	border-style: solid;
}
