.ts-multi-select {
	position: relative;
	height: 40px;
	width: 100%;
}

.ts-multi-select > div:first {
	position: absolute;
	width: 100%;
	height: 100%;
}

.ts-multi-select .ant-select-selection--multiple {
	line-height: var(--input-line-height);
	height: var(--input-height);
	font-size: var(--normal-font-size);
	background: inherit;
	background-color: var(--input-backgroud);
	box-sizing: border-box;
	border-width: var(--input-border-width);
	border-radius: var(--input-border-radius);  
	color: var(--input-color);
	border-color: var(--input-bordercolor); 
}

.ts-multi-select.ant-select-open .ant-select-selection,
.ts-multi-select.ant-select-focused .ant-select-selection, 
.ts-multi-select.ant-select-selection:focus,
.ts-multi-select.ant-select-selection:active {
	box-shadow: none;
}

.ts-multi-select .ant-select-selection__placeholder {
	color: var(--gainsboro);
}

.ts-multi-select .ant-select-selection__rendered {
	line-height: var(--input-line-height);
}

.ts-multi-select.ant-select {
	width: 100%;
}

.ts-multi-select .ant-select-selection-selected-value {
	line-height: var(--input-line-height);
	height: var(--input-height);
}

.ts-multi-select-dropdown .ant-select-dropdown--multiple,
.ant-select-dropdown-menu-item-selected {
	background-color: transparent;
}

.ts-multi-select .ant-select-arrow {
	color: var(--dark-blue-grey);
	font-size: var(--font-size-md);
	font-weight: 500;
	cursor: pointer;
}

.ts-multi-select .ant-select-dropdown {
	border-radius: 0px;
	border: solid 1px rgba(0, 14, 20, 0.498);
}

.ts-multi-select-dropdown .ant-select-dropdown--multiple,
.ts-multi-select-dropdown .ant-select-dropdown-menu-item,
.ts-multi-select-dropdown .ant-select-dropdown-menu-item-group-title {
	border-bottom: solid 1px var(--input-backgroud);
	border-radius: 0px;
	height: 40px;
	padding: 0;
	padding-right: 0 !important;
}


.ts-multi-select.ant-select:hover,
.ts-multi-select.ant-select:focus {
	background-color: transparent;
}

.ts-multi-select .ant-select-selection--multiple:focus {
	border-color: var(--title-color);
	box-shadow: 1px 1px var(--white);  
}

.ts-multi-select .ant-select-selection--multiple:hover {
	border-color: var(--title-color);
	background-color: var(--input-backgroud);
}

.ts-multi-select .ant-select-selection--multiple .ant-select-selection__rendered > ul > li.ant-select-selection__choice {
	width: 80px;
	height: 24px;
	border-radius: 4px;
	margin-top: 6px !important;
	padding: 0;
	background-color: var(--input-backgroud-hover);
	border: none;
}

.ts-multi-select .ant-select-selection--multiple .ant-select-arrow{
	top: 50%;
}

.ts-multi-select .ant-select-selection--multiple .ant-select-selection__rendered > ul > li.ant-select-selection__choice > .ant-select-selection__choice__remove {
	width: 8px;
	height: 13px;
	font-family: FontAwesome5Pro;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: var(--primary-color-dark);
	padding-top: 7px;
}

.ts-multi-select .ant-select-selection__choice__content {
	width: calc(100% - 20px);
	font-family: Cairo;
	font-size: 12px;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: var(--primary-color-dark);
}

.ts-multi-select .ant-select-selection__choice__content .ts-multi-select-label {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 5px;
}

.ts-multi-select .anticon .anticon-check, .ant-select-selected-icon {
	display: none;
}

.ts-multi-select .ant-select-selection__choice__content .ts-checkbox {
	position: absolute;
	opacity: 0;
	z-index: -1;
}

.ts-multi-select-dropdown.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon, 
.ts-multi-select-dropdown.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
	display: none;
}

.ts-multi-select-maxtag-label {
	width: 68px;
	height: 16px;
	font-family: Roboto;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.29;
	letter-spacing: normal;
	color: var(--dark-blue-grey);
	display: inline-block;
	padding-left: 3px;
}

.ts-multi-select, .ant-select .ant-select-focused .ant-select-enabled {
	border: none ;
}

.ts-multi-select-dropdown div.ts-checkbox {
	padding: 10px;
}

.ts-multi-select-dropdown .ant-select-search__field{
	display: none;
}

.ts-multi-select-dropdown  .ts-checkbox input:checked + .check:before {
	-webkit-animation: none;
	animation: none;
}

.ts-multi-select-dropdown .ts-checkbox input:checked + .check::after {
	-webkit-animation: none;
	animation: none;
}

.ts-multi-select-options {
	flex: 0 1 auto;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: center;
	display: flex;
}

.ts-multi-select .ts-multi-select-maxtag {
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ts-multi-select .ts-multi-select-info-maxtag {
	float: left;
	padding-left: 4px;
}

.ts-multi-select .ts-multi-select-info-maxtag > div:first-child {
	float: left;
	width: 20px;
	padding-top: 1px;
	font-weight: 500;
	display: flex;
	justify-content: center;
}

.ts-multi-select .ts-multi-select-info-maxtag > div:last-child {
	float: left;
	width: calc(100% - 30px);
}

.ts-multi-select .ts-multi-select-clear-maxtag {
	position: absolute;
	right: 5px;
}

.ts-multi-select-options > div:first-child {
	float: left;
	width: 40px;
	height: 100%;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ts-multi-select-options .ts-multi-select-label {
	float: left;
	width: calc(100% - 40px);
	height: 40px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 10px 10px 10px 0px;
}

.ts-multi-select .ant-select-selection__choice__content .ts-multi-select-options  {
	display: inline;
	flex: none;
}

.ts-multi-select .ant-select-selection:active {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.ts-multi-select.ant-select-disabled .ant-select-selection--multiple {
	border-color: var(--input-bordercolor-disabled);
	background-color: var(--input-disabled-backgroud);
	color: var(--input-color-disabled);
}

/* disabled */

.ts-multi-select.disabled .ant-select-selection.ant-select-selection--multiple,
.ts-multi-select.disabled .ant-select-selection.ant-select-selection--multiple:hover,
.ts-multi-select.disabled .ant-select-selection.ant-select-selection--multiple:focus {
	border-color: var(--input-bordercolor-disabled);
	background-color: var(--input-disabled-backgroud);
	color: var(--input-color-disabled);
}

.ts-multi-select.ant-select.ant-select-disabled,
.ts-multi-select.ant-select.ant-select-disabled > div > span { 
	opacity: var(--input-opacity-disabled);
	background-color: var(--input-disabled-backgroud);
}

/* readonly */

.ts-multi-select.readonly .ant-select-selection--multiple { 
	border: 1px solid var(--silver-sand);
	line-height: var(--input-line-height);
	height: var(--input-height);
	background-color: var(--ghost-white);
	border-radius: 0;
	cursor: default;
}

.ts-multi-select.readonly.ant-select.ant-select-disabled {
	opacity: 1;
}

.ts-multi-select-dropdown .ts-multi-select-item.ant-select-dropdown-menu-item-disabled {
	background-color: var(--ghost-white);
	color: var(--gainsboro);
	opacity: 1;
}

.ts-multi-select-dropdown .ts-multi-select-item.ant-select-dropdown-menu-item-disabled.ant-select-dropdown-menu-item-selected {
	background-color: var(--white);
	color: var(--yankees-blue);
}

.ts-multi-select-dropdown .ts-multi-select-item {
	padding: 0;
	padding-right: 0 !important;
	box-sizing: border-box;
	border: 1px solid transparent;
}

.ts-multi-select-dropdown .ts-multi-select-item:hover {
	border: 1px solid var(--primary-color);
	background-color: var(--white);
	outline-offset: 0;
}

.ts-multi-select-dropdown .ts-multi-select-item.ant-select-dropdown-menu-item-selected {
	background-color: var(--azure);
	color: var(--primary-color);
	font-weight: 500;
}

.ts-multi-select .ant-select-focused .ant-select-selection {
	box-shadow: none;
}
