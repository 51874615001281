/* START - ant-tree elements */

.ts-tree.ant-tree li {
	font-family: Roboto-Regular;
	margin: 0;
	padding: 0;
	white-space: nowrap;
	list-style: none;
	outline: 0;
}

.ts-tree.ant-tree li ul {
	margin: 0;
	padding: 0 0 0 18px;
}

.ts-tree.ant-tree li .ant-tree-node-content-wrapper {
	padding: 0px;
	line-height: 30px;
	vertical-align: middle;
	width: 85%;
	display: inline-flex;
}

.ts-tree.ant-tree li .ant-tree-switcher {
	line-height: 30px;
	vertical-align: middle;
}

.ts-tree .ant-tree-child-tree > li {
	padding-top: 2px;
}

/* START - :hover */

.ts-tree li.ts-treenode span.ant-tree-node-content-wrapper:hover {
	background-color: transparent;
	color: var(--yankees-blue);
	font-family: Roboto-Regular;
	font-size: var(--font-size);
	font-weight: normal;
	height: 30px;
	width: 85%;
	border-radius: 0px;
	border-top: 1px solid var(--cerulean);
	border-right: 1px solid var(--cerulean);
	border-bottom: 1px solid var(--cerulean);

	border-left: 1px solid var(--cerulean);
	display: inline-flex;
}

.ts-tree li.ts-treenode-expanded span.ant-tree-node-content-wrapper:hover {
	background-color: transparent;
	color: var(--yankees-blue);
	font-family: Roboto-Medium;
	font-size: var(--font-size);
	font-weight: 500;
	height: 30px;
	width: 85%;
	border-radius: 0px;
	border-top: 1px solid var(--cerulean);
	border-right: 1px solid var(--cerulean);
	border-bottom: 1px solid var(--cerulean);

	border-left: 1px solid var(--cerulean);
	display: inline-flex;
}

.ts-tree li.ts-treenode-selected > span.ant-tree-node-content-wrapper:hover {
	background: var(--azure);
	color: var(--cerulean);
	font-family: Roboto-Medium;
	font-size: var(--font-size);
	font-weight: 500;
	letter-spacing: 0.25px;

	border-radius: 0px;
	border-top: 1px solid var(--cerulean);
	border-right: 1px solid var(--cerulean);
	border-bottom: 1px solid var(--cerulean);

	border-left: 1px solid var(--cerulean);

	height: 30px;
	width: 85%;
	display: inline-flex;
}
/* END - :hover */
/* END - ant-tree elements */
/* START - ts-treenode */

.ts-tree li.ts-treenode span.ant-tree-switcher {
	color: var(--yankees-blue);
	font-family: FontAwesome5Pro-Regular;
	font-size: 16px;
	font-weight: normal;
	height: 30px;
	letter-spacing: 0px;
	text-align: center;
	width: 24px;
}

.ts-tree li.ts-treenode span.ant-tree-node-content-wrapper span.ant-tree-title {
	color: var(--yankees-blue);
	font-family: Roboto-Regular;
	font-size: var(--font-size);
	font-weight: normal;
	height: 30px;
	width: 85%;
	padding-left: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0;
}

.ts-tree
li.ts-treenode
span.ant-tree-node-content-wrapper
span.ant-tree-title
> div.ts-tooltip {
	width: 100%;
}

.ts-tree
li.ts-treenode
span.ant-tree-node-content-wrapper
span.ant-tree-title
> div,
.ts-tree
li.ts-treenode
span.ant-tree-node-content-wrapper
span.ant-tree-title
*
> div {
	overflow: hidden;
	text-overflow: ellipsis;
}

/* END - ts-tree-node */
/* START - ts-treenode-expanded */

.ts-tree li.ts-treenode-expanded span.ant-tree-switcher {
	color: var(--yankees-blue);
	font-family: FontAwesome5Pro-Regular;
	font-size: 16px;
	font-weight: 500;
	height: 30px;
	letter-spacing: 0px;
	text-align: center;
	width: 24px;
}

.ts-tree li.ts-treenode-expanded span.ant-tree-node-content-wrapper {
	color: var(--yankees-blue);
	font-family: Roboto-Medium;
	font-size: var(--font-size);
	font-weight: 500;
	height: 30px;
	width: 85%;
	display: inline-flex;
}

.ts-tree
li.ts-treenode-expanded
span.ant-tree-node-content-wrapper
span.ant-tree-title {
	width: 85%;
	padding-left: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0;
}

.ts-tree li.ts-treenode-expanded span.ant-tree-switcher > i {
	margin-top: 7px;
}
/* END - ts-treenode-expanded */
/* START - ts-treenode-selected */

.ts-tree li.ts-treenode-selected > span.ant-tree-switcher {
	border-left: 2px solid var(--input-bordercolor-active);
	background: var(--azure);

	color: var(--cerulean);
	font-family: FontAwesome5Pro-Regular;
	font-size: 16px;
	font-weight: normal;
	height: 30px;
	letter-spacing: 0px;
	text-align: center;
	width: 24px;
}

.ts-tree li.ts-treenode-selected > span.ant-tree-node-content-wrapper {
	background: var(--azure);

	color: var(--cerulean);
	font-family: Roboto-Medium;
	font-size: var(--font-size);
	font-weight: 500;
	letter-spacing: 0.25px;

	height: 30px;
	width: 85%;
	display: inline-flex;
}

.ts-tree
li.ts-treenode-selected
span.ant-tree-node-content-wrapper
span.ant-tree-title {
	width: 85%;
	padding-left: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0;
}

/* END - ts-treenode-selected */
