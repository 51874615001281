.ts-buttongroup {
	width: 100%;
	min-height: var(--normal-height);
	position: relative;
}

.ts-buttongroup .ts-negative-buttongroup {
	float: left;
	height: 100%;
}

.ts-buttongroup .ts-positive-buttongroup {
	float: right;
	height: 100%;
}

/* minitoolbar */

.ts-buttongroup.ts-minitoolbar {
	position: fixed;
	bottom: 0;
	right: 0;
	background-color: var(--white-smoke);
	box-shadow: 0 -2px 8px 0 rgba(21, 41, 53, 0.10);
	z-index: 1000;
}

.ts-buttongroup.ts-minitoolbar .ts-stacklayout .ts-stacklayout-lr,
.ts-buttongroup.ts-minitoolbar .ts-stacklayout .ts-stacklayout-rl {
	top: auto;
	bottom: 4px;
	padding: 0px 32px;
}

.ts-buttongroup.ts-minitoolbar .ts-stacklayout-item .ts-button button.ant-btn.ant-btn-default {
	border: 1px solid transparent;
	box-shadow: none;
}

.ts-buttongroup.ts-minitoolbar .ts-stacklayout-item .ts-button button.ant-btn.ant-btn-default:not([disabled]):hover {
	background: transparent;
	color: var(--primary-color);
	border: 1px solid var(--primary-color);
}
