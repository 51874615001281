.ts-variants-configurator {
	position: relative;
	height: var(--normal-height);
	width: 100%;
}

.ts-variants-configurator > div:first-child {
	position: absolute;
	width: 100%;
	height: 100%;
}

.ts-variants-configurator .ts-input .ant-input-group {
	display: block;
}

.ts-variants-configurator .ts-input input.ant-input {
	padding-right: 40px;
}

.ts-variants-configurator .ts-input .ant-input-wrapper.ant-input-group .ant-input-group-addon {
	position: absolute;
	top: 0;
	right: 0;
	height: 40px;
	width: 40px;
	background-color: transparent;
	border: none;
	z-index: 1;
	display: flex;
	align-items: center;
}

.ts-variants-configurator .ts-input .ant-input-wrapper.ant-input-group input.ant-input:disabled + span.ant-input-group-addon {
	color: var(--input-color-disabled);
}

.ts-variants-configurator .ts-input input.ant-input:disabled + span > .ts-icon {
	cursor: not-allowed;
}

.ts-variants-configurator > .ts-variants-configurator-autocomplete {
	z-index: -1;
	position: absolute;
	height: var(--normal-height);
	width: 100%;
	top: 0;
}

.ts-variants-configurator > .ts-variants-configurator-autocomplete > div {
	height: var(--normal-height);
	width: 100%;
}

.ts-variants-configurator > .ts-variants-configurator-autocomplete > div .ant-input {
	display: none;
}

.ts-variants-configurator-option {
	height: 100%;
	width: 100%;
	padding: 0 8px;
}

.ts-variants-configurator-option > *:first-child {
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}

.ts-variants-configurator-option .ts-variants-configurator-highlighted {
	background-color: var(--input-highlighted);
}

.ts-variants-configurator-dropdown .ant-select-dropdown-menu-item {
	line-height: var(--input-line-height);
	padding: 0;
	height: var(--normal-height);
}

.ts-variants-configurator-dropdown .ant-select-dropdown-menu-item div.ts-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ts-variants-configurator-dropdown .ant-select-dropdown-menu-item:first-child,
.ts-variants-configurator-dropdown .ant-select-dropdown-menu-item:last-child {
	border-radius: initial;
}

.ts-variants-configurator-dropdown .ant-select-dropdown-menu-item,
.ts-variants-configurator-dropdown .ant-select-dropdown-menu-item:hover {
	background-color: var(--white);
}

.ts-variants-configurator-dropdown.ts-variants-configurator-dropdown-autocomplete .ant-select-dropdown-menu-item:not(:hover) .ts-variants-configurator-option-selected {
	background-color: #e6f7ff;
}

.ts-variants-configurator-dropdown .ant-select-dropdown-menu-item .ts-variants-configurator-option, 
.ts-variants-configurator-dropdown .ant-select-dropdown-menu-item .ts-variants-configurator-option-selected {
	border: 1px solid transparent;
}

.ts-variants-configurator-dropdown .ant-select-dropdown-menu-item:hover .ts-variants-configurator-option,
.ts-variants-configurator-dropdown .ant-select-dropdown-menu-item:not(:hover) .ts-variants-configurator-option-selected {
	border: 1px var(--primary-color) solid;
}

.ts-variants-configurator .ts-input-readonly, 
.ts-variants-configurator .ts-input-readonly * {
	height: 100%;
}