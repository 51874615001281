
@keyframes pulse {

	0%   { opacity: 1; }

	100% { opacity: 0; }
}

body {
	margin: 0;
}

.ts-loader {
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--input-backgroud) ;
	opacity: 0.7;
}

.ts-loader-blocks-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	margin: auto;
	width: 2.5em;
	height: 2.5em;
}

.ts-loader-blk {
	height: 35%;
	width: 35%;
	animation: pulse 0.75s ease-in infinite alternate;
	background-color: var(--primary-color);
}

.ts-loader-blk_delay {
	animation-delay: 0.75s;
}