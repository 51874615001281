.ts-multilookup {
	position: relative;
	width: 100%;
	height: var(--input-height);
	line-height: var(--input-line-height);
	font-size: var(--normal-font-size);
	background: inherit;
	background-color: var(--input-backgroud);
	box-sizing: border-box;
	border-width: var(--input-border-width);
	border-style: var(--input-border-style);
	border-radius: var(--input-border-radius);	
	border-color: var(--input-bordercolor);  
	color: var(--input-color);
}

.ts-multilookup:not(.ts-multilookup-readonly):not(.ts-multilookup-disabled):hover {
	box-shadow: 1px 1px transparent; 
	border-color: var(--input-bordercolor-active);  
	background-color: var(--input-backgroud);
	display: flex;
	justify-content: center;
	align-items: center;
}

.ts-multilookup > div {
	height: 100%;
	width: 100%;
}

.ts-multilookup .ts-multilookup-tags {
	position: absolute;
	top: 0;
	width: calc(100% - 40px);
	z-index: 1;
}

.ts-multilookup .ts-multilookup-input {
	width: 100%;
	height: 100%;
}

.ts-multilookup .ts-input,
.ts-multilookup .ts-input .ant-input-wrapper.ant-input-group,
.ts-multilookup .ts-input .ant-input-wrapper.ant-input-group > input {
	height: 100%;
}

.ts-multilookup .ts-input .ant-input-wrapper.ant-input-group > input,
.ts-multilookup .ts-input .ant-input-wrapper.ant-input-group > input:hover,
.ts-multilookup .ts-input .ant-input-wrapper.ant-input-group > input:focus,
.ts-multilookup .ts-input .ant-input-wrapper.ant-input-group .ant-input-group-addon {
	border-color: transparent;
	background-color: transparent;
}

.ts-multilookup .ts-input .ant-input-wrapper.ant-input-group > input {
	visibility: hidden;
}

.ts-multilookup .ts-multilookup-tags > div {
	height: 100%;
	width: 100%;
	margin-top: -8px;
}

.ts-multilookup .ts-multilookup-tags .ts-multi-select,
.ts-multilookup .ts-multilookup-tags .ts-multi-select .ant-select-selection--multiple {
	height: 100%;
}

.ts-multilookup .ts-multilookup-tags .ts-multi-select .ant-select-selection__rendered {
	line-height: normal;
}

.ts-multilookup .ts-multilookup-tags .ts-multi-select .ant-select-selection--multiple,
.ts-multilookup .ts-multilookup-tags .ts-multi-select .ant-select-selection--multiple:hover,
.ts-multilookup .ts-multilookup-tags .ts-multi-select .ant-select-selection--multiple:focus {
	border-color: transparent;
	background-color: transparent;
	cursor: pointer;
}

.ts-multilookup .ts-multilookup-tags .ts-multi-select .ant-select-selection--multiple .ant-select-selection__rendered > ul > li.ant-select-selection__choice {
	margin-top: 0 !important;
	margin: 0 !important;
	padding: 0 !important;	
	margin-right: 4px !important;
	margin-top: 4px !important;
}

.ts-multilookup .ts-multilookup-tags .ts-multi-select .ant-select-selection__choice__content {
	width: calc(100% - 20px);
	padding: 0 !important;
}

.ts-multilookup .ts-multilookup-tags .ts-multi-select .ant-select-selection__choice__content .ts-multi-select-label {
	padding: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ts-multilookup .ts-multilookup-tags input {
	visibility: hidden;
}

.ts-multilookup.ts-multilookup-disabled .ts-input,
.ts-multilookup.ts-multilookup-readonly .ts-input-readonly {
	visibility: hidden;
}

.ts-multilookup.ts-multilookup-disabled .ts-multilookup-tags,
.ts-multilookup.ts-multilookup-readonly .ts-multilookup-tags {
	width: 100%;
}
