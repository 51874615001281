.ts-number-label {
	display: flex;
	font-family: var(--font-family);
	font-size: var(--normal-font-size);
	color: var(--label-color);
	font-weight: 400;
	height: var(--normal-height);
	align-items: center;
	justify-content: center;
}

.ts-number-label.ts-title {
	color: var(--primary-color-dark); 
	font-size: var(--font-size-xxl);  
	height: var(--large-height);
	font-family: var(--font-family-header-label);
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
}

.ts-number-label .ts-number-label-main {
	text-align: end;
	width: 100%;
	padding: 0 8px;
}

.ts-number-label .ts-number-label-bold {
	font-weight: bold;
}

.ts-number-label .ts-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ts-number-label .ts-number-label-main .info {
	color: var(--success-color);
}

.ts-number-label .ts-number-label-main .warning {
	color: var(--warning-color);
}

.ts-number-label .ts-number-label-main .error {
	color: var(--danger-color);
}

/* regole per utilizzo in pannello parametri in output */

.ts-number-label-param {
	min-height: 21px;
	color: var(--yankees-blue);
}

/* label numeriche */

.ts-number-label.ts-number-label-param {
	height: 21px;
	margin-bottom: 12px;
}

.ts-number-label-param  .ts-number-label-main.ts-truncate {
	text-align: left;
	color: var(--yankees-blue);
	padding-left: 0;
}