.ts-input-number {
	width: 100%;
	height: var(--normal-height);
}

.ts-input-number .ts-number,
.ts-input-number .ts-number-integer,
.ts-input-number .ts-number-fraction,
.ts-input-number .ts-number-basic-arrows,
.ts-input-number .ts-number-mobile-arrows,
.ts-input-number .ts-number-fraction .ts-number-decimal,
.ts-input-number .ts-number-fraction .ts-number-separator {
	background-color: transparent;
	height: 100%;
	float: left;
}

.ts-input-number .ts-number {
	border: 1px solid var(--input-bordercolor);
	background-color: var(--input-backgroud);
	outline-color: transparent;
	padding: 0 8px;
}

.ts-input-number .ts-number[contenteditable='false'] div {
	cursor: default;
}

.ts-input-number:not(.ts-number-disabled):not(.ts-number-readonly) .ts-number:hover,
.ts-input-number:not(.ts-number-disabled):not(.ts-number-readonly) .ts-number:focus {
	border: 1px solid var(--primary-color);
}

.ts-input-number .ts-number-integer {
	text-align: right;
	position: relative;
}

.ts-input-number .ts-number-integer.placeholder:empty::before {
	content: attr(placeholder);
	color: var(--input-color-disabled);
	left: 0;
	text-align: left;
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ts-input-number .ts-number-mobile-arrows .ts-number-mobile-arrow-plus,
.ts-input-number .ts-number-mobile-arrows .ts-number-mobile-arrow-minus {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 40px;
	border: 1px solid var(--input-bordercolor);
}

.ts-input-number .ts-number-mobile-arrows .ts-number-mobile-arrow-plus {
	border-left: 0;
}

.ts-input-number .ts-number-mobile-arrows .ts-number-mobile-arrow-minus {
	border-right: 0;
}

.ts-input-number .ts-number-basic-arrows .ts-number-basic-arrow-plus,
.ts-input-number .ts-number-basic-arrows .ts-number-basic-arrow-minus {
	height: 20px;
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--input-bordercolor);
	border-left: 0;
}

.ts-input-number .ts-number-mobile-arrows .ts-number-mobile-arrow-plus,
.ts-input-number .ts-number-mobile-arrows .ts-number-mobile-arrow-minus,
.ts-input-number .ts-number-basic-arrows .ts-number-basic-arrow-plus,
.ts-input-number .ts-number-basic-arrows .ts-number-basic-arrow-minus {
	cursor: pointer;
}

.ts-input-number.ts-number-disabled .ts-number .ts-number-integer,
.ts-input-number.ts-number-disabled .ts-number .ts-number-decimal,
.ts-input-number.ts-number-disabled .ts-number .ts-number-separator,
.ts-input-number.ts-number-disabled .ts-number-mobile-arrows .ts-number-mobile-arrow-plus,
.ts-input-number.ts-number-disabled .ts-number-mobile-arrows .ts-number-mobile-arrow-minus,
.ts-input-number.ts-number-disabled .ts-number-basic-arrows .ts-number-basic-arrow-plus,
.ts-input-number.ts-number-disabled .ts-number-basic-arrows .ts-number-basic-arrow-minus {
	cursor: not-allowed;
}

.ts-input-number.ts-number-disabled .ts-number-mobile-arrows .ts-number-mobile-arrow-plus i,
.ts-input-number.ts-number-disabled .ts-number-mobile-arrows .ts-number-mobile-arrow-minus i,
.ts-input-number.ts-number-disabled .ts-number-basic-arrows .ts-number-basic-arrow-plus i,
.ts-input-number.ts-number-disabled .ts-number-basic-arrows .ts-number-basic-arrow-minus i {
	color: var(--input-bordercolor);
}

.ts-input-number.ts-number-disabled .ts-number {
	border-color: var(--input-bordercolor-disabled);    
	background-color: var(--input-disabled-backgroud);
	color: var(--input-color-disabled);
	opacity: var(--input-opacity-disabled);
}

.ts-input-number .ts-number-integer,
.ts-input-number .ts-number-decimal,
.ts-input-number .ts-number-separator {
	height: 100%;
	width: 100%;
	border: none;
	line-height: var(--input-line-height);
	outline: none;
}

.ts-input-number .ts-number-separator {
	font-weight: bold;
	width: 6px;
	display: flex;
	justify-content: center;
}

/* readonly */

.ts-input-number.ts-number-readonly .ts-number {
	border: 1px solid var(--silver-sand);
	background-color: var(--ghost-white);
	color: var(--yankees-blue);
}

