.ts-small-page .ts-page {
	font-size: var(--small-font-size);
}

.ts-normal-page .ts-page {
	font-size: var(--normal-font-size);
}

.ts-large-page .ts-page {
	font-size: var(--large-font-size);
}

.ts-x-large-page .ts-page {
	font-size: var(--x-large-font-size);
}

.ts-xx-large-page .ts-page {
	font-size: var(--xx-large-font-size);
}

.ts-xxx-large-page .ts-page {
	font-size: var(--xxx-large-font-size);
}

.ts-page {
	padding-top: 10px;  
}

.ts-content {
	/* margin: 0 auto; */
	/* width: 90%; */
	width: var(--page-width);
	max-width: var(--max-page-width);
	height: 100%;
	background-color: var(--white);
	margin: auto;
	box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);    
	margin-bottom: 10px;
	padding: 10px;
	padding-bottom: 20px;
}

.ts-content-no-header {
	/* margin: 0 auto; */
	/* width: 90%; */
	/* width: var(--page-width);
  max-width: var(--max-page-width); */
	width: 100%;
	height: 100%;
	background-color: var(--white);
	/* margin: auto; */
	/* box-shadow: 0 2px 8px 0 rgba(0,0,0,.1);     */
	/* margin-bottom: 10px; */
	/* padding: 10px;
  padding-bottom: 20px; */
}

.ts-page-no-header{
	padding-top: 0;
}

.ts-content-wrapper.ts-content-wrapper-toolbar .ts-content {
	margin-bottom: 50px;
}

.ts-content-wrapper {
	width: 100%;
}

.sticky + .ts-content-wrapper  {
	padding-top: 76px;  
}
