.ts-lookup {
	position: relative;
	height: var(--normal-height);
	width: 100%;
}

.ts-lookup > div:first-child {
	position: absolute;
	width: 100%;
	height: 100%;
}

.ts-lookup .ts-input .ant-input-group {
	display: block;
}

.ts-lookup .ts-input input.ant-input {
	padding-right: 40px;
}

.ts-lookup .ts-input .ant-input-wrapper.ant-input-group .ant-input-group-addon {
	position: absolute;
	top: 0;
	right: 0;
	height: 40px;
	width: 40px;
	background-color: transparent;
	border: none;
	z-index: 1;
	display: flex;
	align-items: center;
}

.ts-lookup .ts-input .ant-input-wrapper.ant-input-group input.ant-input:disabled + span.ant-input-group-addon {
	color: var(--input-color-disabled);
}

.ts-lookup .ts-input input.ant-input:disabled + span > .ts-icon {
	cursor: not-allowed;
}

.ts-lookup > .ts-lookup-widget {
	z-index: -1;
	position: absolute;
	height: var(--normal-height);
	width: 100%;
	top: 0;
}

.ts-lookup > .ts-lookup-widget > div {
	height: var(--normal-height);
	width: 100%;
}

.ts-lookup > .ts-lookup-widget > div .ant-input {
	display: none;
}

/* TsLookup option */

.ts-lookup-option {
	height: 100%;
	width: 100%;
	padding: 0 8px;
}

.ts-lookup-option > *:first-child {
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}

.ts-lookup-option .ts-lookup-highlighted {
	background-color: var(--input-highlighted);
}

.ts-lookup-dropdown .ant-select-dropdown-menu-item {
	line-height: var(--input-line-height);
	padding: 0;
	height: var(--normal-height);
}

.ts-lookup-dropdown .ant-select-dropdown-menu-item div.ts-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ts-lookup-dropdown .ant-select-dropdown-menu-item:first-child,
.ts-lookup-dropdown .ant-select-dropdown-menu-item:last-child {
	border-radius: initial;
}

.ts-lookup-dropdown .ant-select-dropdown-menu-item,
.ts-lookup-dropdown .ant-select-dropdown-menu-item:hover {
	background-color: var(--white);
}

.ts-lookup-dropdown.ts-lookup-dropdown-autocomplete .ant-select-dropdown-menu-item:not(:hover) .ts-lookup-option-selected {
	background-color: #e6f7ff;
}

.ts-lookup-dropdown.ts-lookup-dropdown-autocomplete .ts-lookup-pagination .ant-pagination-options {
	display: none;
}

.ts-lookup-dropdown .ant-select-dropdown-menu-item:not(.ts-lookup-pagination) .ts-lookup-option, 
.ts-lookup-dropdown .ant-select-dropdown-menu-item:not(.ts-lookup-pagination) .ts-lookup-option-selected:not(.ts-lookup-pagination) {
	border: 1px solid transparent;
}

.ts-lookup-dropdown .ant-select-dropdown-menu-item:hover:not(.ts-lookup-pagination) .ts-lookup-option,
.ts-lookup-dropdown .ant-select-dropdown-menu-item:not(:hover):not(.ts-lookup-pagination) .ts-lookup-option-selected {
	border: 1px var(--primary-color) solid;
}

.ts-lookup .ts-input-readonly, 
.ts-lookup .ts-input-readonly * {
	height: 100%;
}

@-webkit-keyframes syncrotating /* Safari and Chrome */ {

	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes syncrotating {

	from {
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.ts-lookup .sync-icon {
	-webkit-animation: syncrotating 2s linear infinite;
	-moz-animation: syncrotating 2s linear infinite;
	-ms-animation: syncrotating 2s linear infinite;
	-o-animation: syncrotating 2s linear infinite;
	animation: syncrotating 2s linear infinite;
}