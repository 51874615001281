.ts-pagination .ant-pagination-prev .ant-pagination-item-link,
.ts-pagination .ant-pagination-next .ant-pagination-item-link {
	border: none;
	background-color: inherit;
}

.ts-pagination .ant-pagination-prev a,
.ts-pagination .ant-pagination-next a {
	color: #0d9ac6;
}

.ts-pagination .ant-pagination-prev .ant-pagination-item-link:after,
.ts-pagination .ant-pagination-next .ant-pagination-item-link:after {
	font-weight: 1000;
}

.ts-pagination .ant-pagination-total-text {
	color: #0d9ac6;
}

.ts-pagination.ts-pagination-simple .ant-pagination-item,
.ts-pagination.ts-pagination-simple .ant-pagination-jump-prev,
.ts-pagination.ts-pagination-simple .ant-pagination-jump-next {
	display: none;
}

.ts-pagination.ts-pagination-extended .ant-pagination-item-active {
	border: none;
}

.ts-pagination.ts-pagination-extended .ant-pagination-item:hover {
	border-radius: 2px;
	background-color: var(--input-backgroud-hover);
}

.ts-pagination.ts-pagination-extended .ant-pagination-options-quick-jumper input {
	border-radius: 0;
}

.ts-pagination.ts-pagination-extended .ant-pagination-options-quick-jumper input:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.ts-pagination .pagination-top {
	width: 100%;
	display: block;
	text-align: right;
}

.ts-pagination .pagination-div {
	width: auto;
	float: right;
	color: #000;
}

.ts-pagination .pagination-div-buttons {
	width: 32px;
	cursor: pointer;
	color: #0d9ac6;
}

.ts-pagination-raw {
	height: 12px;
}

.ts-pagination-raw > div {
	float: left;
}

.ts-pagination-raw .ts-raw {
	float: left;
	color: var(--cadet);
	font-size: 10px;
	cursor: pointer;
	margin-left: 8px;
}

.ts-pagination-raw > div:first-child {
	color: var(--dark-blue-grey);
	font-size: 10px;
	font-weight: 500;
}

.ts-pagination-raw .ts-raw:hover,
.ts-pagination-raw .ts-raw-selected {
	color: var(--primary-color);
}
