.ts-tag,
.ts-tag-filter {
	height: 24px;
	font-family: Cairo;
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	border-radius: 4px;
}

.ts-tag {
	border: solid 1px;
	padding: 0 8px 0 8px;
	text-transform: uppercase;
	margin-right: 8px;
	display: inline-block;
	-webkit-box-sizing: border-box;
	/* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;
	/* Firefox, other Gecko */
	box-sizing: border-box;
	/* Opera/IE 8+ */

}

.ts-tag-filter {
	padding: 0 0 0 8px;
	margin-right: 8px;
	background-color: var(--azure);
	color: var(--dark-imperial-blue);
	display: inline-block;
}

.ts-tag-filter .ts-tag-icon-close {
	height: 24px;
	width: 24px;
	color: var(--dark-imperial-blue);
	text-align: center;
}