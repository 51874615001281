.ts-switch {
	height: var(--normal-height);
	display: flex;
	align-items: center;
	justify-content: center;
}

.ts-switch .switch.disabled-switch {
	cursor: not-allowed;
}

.ts-switch .switch:not(.disabled-switch) {
	cursor: pointer;
}

.ts-switch .switch {
	position: relative;
	display: inline-block;
	min-width: 44px;
	height: 22px;
}

.ts-switch .switch input { 
	opacity: 0;
	width: 0;
	height: 0;
}

.ts-switch .slider {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--cadet);
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.ts-switch .slider:before {
	position: absolute;
	content: '';
	height: 18px;
	width: 18px;
	left: 3px;
	bottom: 2px;
	background-color: var(--white);
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.ts-switch input:checked + .slider {
	background-color: var(--primary-color);
}

.ts-switch input:focus + .slider {
	box-shadow: 0 0 1px var(--primary-color);
}

.ts-switch input:checked + .slider:before {
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
}

.ts-switch .slider.round {
	border-radius: 22px;
}

.ts-switch .slider.round:before {
	border-radius: 50%;
}

.ts-switch label.switch.disabled-switch .slider {
	background-color: var(--silver-sand);
}