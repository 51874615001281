.ts-menu-actions {
	max-height: 325px;
	overflow-y: auto;
}

.ts-menu-actions .ts-menu-action:not(.ts-menu-action-disable) {
	cursor: pointer;
}

.ts-menu-actions .ts-menu-action.ts-menu-action-disable {
	cursor: not-allowed;
}

.ts-menu-actions .ts-menu-action {
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: left;
	font-size: var(--large-font-size);
	border-bottom: 1px solid var(--gainsboro);
}

.ts-menu-actions .ts-menu-action:last-child {
	border-bottom: none;
}

.ts-menu-actions .ts-menu-action:not(.ts-menu-action-disable) .ts-menu-action-normal {
	color: var(--primary-color);
}

.ts-menu-actions .ts-menu-action:not(.ts-menu-action-disable) .ts-menu-action-red {
	color: var(--maximum-red);
}

.ts-menu-actions .ts-menu-action.ts-menu-action-disable .ts-menu-action-normal {
	color: var(--input-color-disabled);
}