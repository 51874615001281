/* Tooltip */

.ts-tooltip {
	display: inline-block;
}

.ts-tooltip-info .ant-popover-inner-content {
	text-align: left;
	font-weight: 500;
	color: var(--yankees-blue);
	font-size: var(--small-font-size);
	font-family: Cairo;
	letter-spacing: 0px;
	line-height: 24px;
}

.ts-tooltip-fab.ant-popover-placement-topRight {
	position: fixed;
}

.ts-tooltip-fab .ant-popover-title {
	width: 100%;
	height: 48px;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 2.5;
	letter-spacing: normal;
	color: var(--label-color);
}

.ts-tooltip-fab .ant-popover-inner-content {
	cursor: pointer;
	width: 280px;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	overflow-wrap: break-word;
	line-height: 2.5;
	letter-spacing: normal;
	color: var(--primary-color);
	padding-top: 16px;
	padding-left: 16px;
	padding-right: 16px;
	margin: 0%;
}

.ts-tooltip-fab-children {
	display: flex;
	position: fixed;
	z-index: 250;
	bottom: 115px;
	right: 75px;
}

.ts-tooltip-fab i {
	position: absolute;
	right: 12.8px;
	line-height: 2.5;
}
  
.ts-tooltip-overlay .ant-popover-title {
	background-color: var(--white); 
	box-shadow: 0 2px 0 0 #dbd7d7;
	font-family: Roboto;
	font-size: var(--font-size-lg);
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	padding: 10px;
	border-bottom: 2px solid #e8e8e8;
}

.ts-tooltip-overlay .ant-popover-inner{
	border-radius: 0px;  
	background-color: var(--white);  
}

.ts-tooltip-overlay .ant-popover-inner-content {
	font-family: Roboto;
	font-size: var(--font-size-lg);
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: var(--title-color);
	box-shadow: 0 -4px 8px 0 rgba(32, 14, 14, 0.1);    
	background-color: var(--white);
	border: solid 1px var(--white);
	border-radius: 0px;      
}

.ts-tooltip-overlay .ant-popover-title + .ant-popover-inner-content {
	box-shadow: none;
	border: none; 
	padding: 0px;
	padding-bottom: 6px;
}

.ts-tooltip-overlay .ant-popover-title + .ant-popover-inner-content p {
	padding: 6px;
	padding-bottom: 8px;
	padding-top: 8px;
	margin: 0px;
	box-shadow: 0 1px 0 0 #dbd7d7;
}

.ts-tooltip-overlay .ant-popover-title + .ant-popover-inner-content .ts-button,
.ts-tooltip-overlay .ant-popover-title + .ant-popover-inner-content .ts-button button {
	width: 100%;
	margin: 0px !important;
	border-radius: 0px;
}

.ts-tooltip-overlay .ant-popover-title + .ant-popover-inner-content p:last-child {
	box-shadow: none;
}

.ts-tooltip-dark .ant-popover-inner-content {
	background-color: var(--primary-color-dark);
	color: var(--white);
	border-radius: 2px;
	border: solid 1px var(--primary-color-dark);
}

.ts-tooltip-dark.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ts-tooltip-dark.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ts-tooltip-dark.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow,
.ts-tooltip-dark.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ts-tooltip-dark.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ts-tooltip-dark.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow,
.ts-tooltip-dark.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ts-tooltip-dark.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ts-tooltip-dark.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow,
.ts-tooltip-dark.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ts-tooltip-dark.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ts-tooltip-dark.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
	border-bottom-color: var(--primary-color-dark);
	border-right-color: var(--primary-color-dark); 
	border-left-color: var(--primary-color-dark); 
	border-top-color: var(--primary-color-dark); 
}
