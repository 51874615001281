/* SelectAdv */

.ts-select-adv {
	height: var(--normal-height);
	width: 100%;
	background-color: var(--input-backgroud);
	color: var(--input-value);
}

.ts-select-adv .ts-select-adv-wrapper,
.ts-select-adv .ts-select-adv-wrapper .ts-select-adv-autocomplete,
.ts-select-adv
.ts-select-adv-wrapper
.ts-select-adv-autocomplete
.ant-select-selection.ant-select-selection--single,
.ts-select-adv
.ts-select-adv-wrapper
.ts-select-adv-autocomplete
.ant-select-selection__rendered {
	height: 100%;
	width: 100%;
}

.ts-select-adv .ts-select-adv-wrapper .ant-select-selection {
	background-color: var(--input-backgroud);
}

.ts-select-adv .ts-select-adv-wrapper .ts-select-adv-autocomplete {
	font-size: var(--large-font-size);
}

.ts-select-adv
.ts-select-adv-wrapper
.ts-select-adv-autocomplete
.ant-select-selection__rendered {
	margin: 0;
}

.ts-select-adv
.ts-select-adv-wrapper
.ts-select-adv-autocomplete
.ant-select-selection-selected-value {
	position: absolute;
	padding-right: 40px;
	padding-left: 16px;
	line-height: var(--input-line-height);
}

.ts-select-adv
.ts-select-adv-wrapper
.ts-select-adv-autocomplete
.ant-select-arrow {
	position: absolute;
	height: var(--normal-height);
	width: 40px;
	right: 0;
}

.ts-select-adv
.ts-select-adv-wrapper
.ts-select-adv-autocomplete
.ant-select-selection {
	border-radius: initial;
}

.ts-select-adv
.ts-select-adv-wrapper
.ant-select-focused
.ant-select-selection {
	box-shadow: none;
}

.ts-select-adv .ant-select-focused .ant-select-selection,
.ts-select-adv .ant-select-select--inline .ant-select-select__field:focus {
	/* color: var(--primary-color); */
	background-color: var(--input-backgroud);
}

/* TsSelectAdv option */

.ts-select-adv-option {
	height: 100%;
	width: 100%;
	display: table;
	padding-right: 40px;
	padding-left: 16px;
}

.ts-select-adv-option.ts-select-adv-option-selected {
	border: 1px solid var(--primary-color);
}

.ts-select-adv-option > *:first-child {
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}

.ts-select-adv-option .ts-select-adv-option-icon {
	position: absolute;
	right: 0;
	height: 100%;
	width: 40px;
	display: table;
}

.ts-select-adv-option .ts-select-adv-option-icon > *:first-child {
	right: 0;
	height: 100%;
	width: 100%;
	display: table-cell;
	vertical-align: middle;
}

.ts-select-adv-dropdown .ant-select-dropdown-menu-item {
	line-height: var(--input-line-height);
	padding: 0;
	height: var(--normal-height);
}

.ts-select-adv-dropdown .ant-select-dropdown-menu-item:hover {
	background-color: var(--input-backgroud-hover);
	color: var(--primary-color);
}

.ts-select-adv-dropdown .ant-select-dropdown-menu-item:first-child,
.ts-select-adv-dropdown .ant-select-dropdown-menu-item:last-child {
	border-radius: initial;
}
