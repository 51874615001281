.ts-select.ant-select,
.ts-select .ant-select-selection--single {
	line-height: var(--input-line-height);
	height: var(--input-height);
	font-size: var(--normal-font-size);
	background: inherit;
	background-color: var(--input-backgroud);
	box-sizing: border-box;
	border-width: var(--input-border-width);
	border-radius: var(--input-border-radius);  
	border-color: transparent;  
	color: var(--input-color);
}

.ts-select .ant-select-selection__placeholder {
	color: var(--gainsboro);
}

.ts-select .ant-select-selection__rendered {
	line-height: var(--input-line-height);
}

.ts-select.ant-select {
	width: 100%;
}

.ts-select .ant-select-selection:active {
	box-shadow: none;
}

.ts-select .ant-select-selection--single {
	border-color: var(--input-bordercolor);  
}

.ts-select .ant-select-selection-selected-value {
	line-height: var(--input-line-height);
	height: var(--input-height);
}

.ts-select-dropdown .ant-select-dropdown-menu-item.ts-select-item > div > div:first-child {
	overflow: hidden;
	text-overflow: ellipsis;
	width: calc(100% - 16px);
}

.ts-select-dropdown .ant-select-dropdown-menu-item.ts-select-item > div > div:last-child {
	width: 16px;
}

.ts-select .ant-select-arrow {
	color: var(--dark-blue-grey);
	font-size: var(--font-size-md);
	font-weight: 500;
}

.ts-select .ant-select-dropdown {
	border-radius: 0px;
	border: solid 1px rgba(0, 14, 20, 0.498);
}

.ts-select-dropdown .ant-select-dropdown-menu-item,
.ts-select-dropdown .ant-select-dropdown-menu-item-group-title {
	border-bottom: solid 1px var(--input-backgroud);
	border-radius: 0px;
	height: var(--normal-height);
}

.ts-select.ant-select:hover,
.ts-select.ant-select:focus {
	background-color: transparent;
}

.ts-select .ant-select-selection--single:focus {
	border-color: var(--title-color);
	box-shadow: 1px 1px var(--white);  
}

.ts-select .ant-select-selection--single:hover {
	border-color: var(--title-color);
	background-color: var(--input-backgroud);
}

/* disabled */

.ts-select.disabled .ant-select-selection.ant-select-selection--single,
.ts-select.disabled .ant-select-selection.ant-select-selection--single:hover,
.ts-select.disabled .ant-select-selection.ant-select-selection--single:focus {
	border-color: var(--input-bordercolor-disabled);
	background-color: var(--input-disabled-backgroud);
	color: var(--input-color-disabled);
}

.ts-select.ant-select.ant-select-disabled,
.ts-select.ant-select.ant-select-disabled > div > span { 
	opacity: var(--input-opacity-disabled);
	background-color: var(--input-disabled-backgroud);
}

/* readonly */

.ts-select.readonly.ts-select .ant-select-selection--single { 
	border: 1px solid var(--silver-sand);
	line-height: var(--input-line-height);
	height: var(--input-height);
	background-color: var(--ghost-white);
	border-radius: 0;
	cursor: default;
}

.ts-select.readonly.ant-select.ant-select-disabled {
	opacity: 1;
}

.ts-select.readonly .ant-select-selection-selected-value {
	background-color: transparent;
	cursor: default;
	color: var(--yankees-blue);
}

.ts-select-dropdown .ts-select-item.ant-select-dropdown-menu-item-disabled {
	background-color: var(--ghost-white);
	color: var(--gainsboro);
	opacity: 1;
}

.ts-select-dropdown .ts-select-item.ant-select-dropdown-menu-item-disabled.ant-select-dropdown-menu-item-selected {
	background-color: var(--white);
	color: var(--yankees-blue);
}

.ts-select-dropdown .ts-select-item.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
	border: 1px solid var(--primary-color);
	background-color: var(--white);
	outline-offset: 0;
}

.ts-select-dropdown .ant-select-dropdown-menu-item-selected {
	background-color: var(--azure);
	color: var(--primary-color);
	font-weight: 500;
}

.ts-select.ant-select-open .ant-select-selection,
.ts-select.ant-select-focused .ant-select-selection {
	box-shadow: none;
}

.ts-select-noautosearch,
.ts-select-autosearch {
	position: relative;
	height: var(--input-height);
	width: 100%;
}

.ts-select-noautosearch .ts-input {
	position: absolute;
	opacity: 0;
	z-index: -1;
}

.ts-select-noautosearch .ts-select {
	position: absolute;
	z-index: 1;
	left: 0;
}

.ts-select-autosearch .ts-input {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 1;
	z-index: 1;
	border: 1px solid var(--input-bordercolor);
	background-color: var(--input-backgroud);
}

.ts-select-autosearch .ts-select {
	position: absolute;
	z-index: -1;
}

.ts-select-autosearch .ts-input .ant-input-wrapper.ant-input-group > input,
.ts-select-autosearch .ts-input .ant-input-wrapper.ant-input-group > input:hover {
	border: none;
	background-color: transparent;
}

.ts-select-autosearch.focus .ts-input,
.ts-select-autosearch:hover .ts-input {
	border: 1px solid var(--input-bordercolor-active);
}

.ts-select-autosearch .ts-input .ant-input-wrapper.ant-input-group .ant-input-group-addon {
	z-index: 1;
	border: none;
	background: transparent;
	padding: 0;
}

.ts-select-autosearch .ts-input .ant-input-wrapper.ant-input-group .ant-input-group-addon > div {
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ts-select-autosearch .ts-select .ant-select-arrow {
	display: none;
}
