/** Header */

.ts-tableadv .ts-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: default;
}

.ts-tableadv .ts-table-td-content {
	width: 100%;
}

.ts-tableadv .ts-tableadv-header {
	width: 100%;
	background: var(--white);
	border: 1px solid  var(--quick-silver);
	border-right: 0;
	border-left: 0;
	box-sizing: content-box;
}

.ts-tableadv .ts-tableadv-empty {
	float: left;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ts-tableadv .ts-tableadv-col {
	float: left;
	display: flex;
	align-items: center;
	position: relative;
	font-weight: 500;
	color: var(--primary-color-dark);
}

.ts-tableadv .ts-tableadv-resize {
	position: absolute;
	right: 1px;
	width: 5px;
	float: right;
	background-color: transparent;
}

.ts-tableadv .ts-tableadv-resize:hover {
	background-color: var(--white-smoke);
}

.ts-tableadv .ts-tableadv-row {
	border: 1px solid  var(--quick-silver);
	border-right: 0;
	border-left: 0;
	border-top: 0;
	width: 100%;
}

.ts-tableadv .ts-tableadv-row .ts-group-arrow .ts-icon.fontawe-icon-blue::before,
.ts-tableadv .ts-tableadv-row .ts-action-pagination .ts-icon.fontawe-icon-blue::before {
	color: var(--primary-color-dark);
}

.ts-tableadv .ts-tableadv-row.ts-tableadv-row-expanded {
	background-color: var(--white-smoke);
}

.ts-tableadv .ts-tableadv-row > div {
	float: left;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ts-tableadv .ts-tableadv-header .ts-selection,
.ts-tableadv .ts-tableadv-row .ts-selection {
	float: left;
	width: 40px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
}

.ts-tableadv .ts-tableadv-row .ts-group-arrow {
	width: 32px;
	float: left;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ts-tableadv .ts-tableadv-row .ts-group {
	float: left;                       
	width: 100%;
	display: flex;
	align-items: center;
	color: var(--dark-blue-grey);
	padding-right: 8px;
}

.ts-tableadv .ts-tableadv-col,
.ts-tableadv .ts-tableadv-cell {
	padding: 0 5px;
	float: left;
	display: flex;
	align-items: center;
}

.ts-tableadv .ts-tableadv-cell {
	color: var(--dark-blue-grey);
}

.ts-tableadv .ts-tableadv-cell-start .ts-table-td-content {
	display: flex;
	justify-content: flex-start;
}

.ts-tableadv .ts-tableadv-cell-center .ts-table-td-content {
	display: flex;
	justify-content: center;
}

.ts-tableadv .ts-tableadv-cell-end .ts-table-td-content {
	display: flex;
	justify-content: flex-end;
}

.ts-tableadv .ts-tableadv-info {
	position: absolute;
	z-index: 1;
	width: 100%;
	background: radial-gradient(circle, rgba(255,255,255,0.9) 20%, rgba(242,245,248,0.5) 100%);
}

.ts-tableadv .ts-tableadv-info > div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ts-tableadv .ts-tableadv-info .ts-tableadv-info-standard-icon {
	float: left;
	margin-right: 5px;
	display: flex;
	align-items: center;
}

.ts-tableadv .ts-tableadv-info .ts-tableadv-info-standard-label {
	float: left;
	display: flex;
	align-items: center;
}

.ts-tableadv .ts-action-group,
.ts-tableadv .ts-action-group .ts-action-custom,
.ts-tableadv .ts-action-group .ts-action-pagination {
	float: left;
}

.ts-tableadv .ts-action-group .ts-action-pagination .ts-action-pagination-prev,
.ts-tableadv .ts-action-group .ts-action-pagination .ts-action-pagination-next {
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	float: left;
}

.ts-tableadv .ts-action-group .ts-action-custom .ts-action {
	float: left;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ts-tooltip-table-pagination.ts-tooltip-overlay .ant-popover-inner-content {
	background-color: var(--primary-color-dark);
	color: var(--white);
	border-radius: 2px;
	border: solid 1px var(--primary-color-dark);
}

.ts-tooltip-table-pagination.ts-tooltip-overlay.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
	border-bottom-color: var(--primary-color-dark);
	border-right-color: var(--primary-color-dark); 
}