.ts-download {
	width: 100%;
	position: relative;
}

.ts-download > div:first-child {
	float: left;
	width: calc(100% - 100px);
}

.ts-download > div:last-child {
	float: right;
}

.ts-download > div:last-child > .ts-button {
	width: 100%;
}

.ts-download > div:last-child > .ts-button > button {
	width: 100%;
	height: 40px;
}
