.ts-anchor .ant-anchor-ink,
.ts-anchor-noaffix .ant-anchor-ink{
	color: var(--dark-blue-grey);
}

.ts-anchor .ant-anchor {
	margin-right: 8px;
	margin-left: 8px;
	color: var(--dark-blue-grey);
	position: fixed;
	z-index: 10;
	min-width: 150px;
}

.ts-header .ts-anchor-noaffix .ant-anchor {
	margin-right: 8px;
	margin-left: 8px;
	color: var(--dark-blue-grey);
	z-index: 10;
	/* min-width: 150px; */
}

.ts-header.ts-header-small.ant-layout-header .ts-anchor-noaffix .ant-anchor,
.ts-header.ant-layout-header .ts-anchor-noaffix .ant-anchor {
	position: fixed;
}

/* per garantire la visualizzazione corretta dell'icona nella header e allo scrolling */

.ts-header.ant-layout-header .ts-anchor-noaffix .ant-anchor {
	line-height: 62px;
}

.ts-header.ts-header-small.ant-layout-header .ts-anchor-noaffix .ant-anchor {
	line-height: 38px;
}

.ts-anchor.ts-anchor-right .ant-anchor,
.ts-header.ant-layout-header .ts-anchor-noaffix.ts-anchor-right .ant-anchor {
	right: 30px; 
}

.ts-anchor.ts-anchor-left .ant-anchor {
	left: 30px;
}

.ts-anchor.ts-anchor-calc-right .ant-anchor,
.ts-header.ant-layout-header .ts-anchor-noaffix.ts-anchor-calc-right .ant-anchor {
	right: calc(50% - var(--max-page-width) / 2);
}

.ts-anchor.ts-anchor-calc-left .ant-anchor {
	left: calc(50% - var(--max-page-width) / 2);
}

.ts-anchor.ant-anchor-wrapper,
.ts-anchor-noaffix.ant-anchor-wrapper {
	background-color: transparent;
}

.ts-anchor .ant-anchor-link.ant-anchor-link-active,
.ts-anchor-noaffix .ant-anchor-link.ant-anchor-link-active {
	background-color: var(--azure);
	padding-right: 16px;
}

.ts-anchor.ts-anchor-right .ant-anchor-link.ant-anchor-link-active,
.ts-anchor.ts-anchor-calc-right .ant-anchor-link.ant-anchor-link-active,
.ts-anchor-noaffix.ts-anchor-right .ant-anchor-link.ant-anchor-link-active,
.ts-anchor-noaffix.ts-anchor-calc-right .ant-anchor-link.ant-anchor-link-active {
	border-right: 2px solid var(--primary-color);
}

.ts-anchor.ts-anchor-left .ant-anchor-link.ant-anchor-link-active,
.ts-anchor.ts-anchor-calc-left .ant-anchor-link.ant-anchor-link-active {
	border-left: 2px solid var(--primary-color);
}

.ts-anchor a.ant-anchor-link-title.ant-anchor-link-title-active,
.ts-anchor-noaffix a.ant-anchor-link-title.ant-anchor-link-title-active  {
	color: var(--primary-color);
}

.ts-anchor a.ant-anchor-link-title:hover,
.ts-anchor-noaffix a.ant-anchor-link-title:hover {
	color: var(--dark-blue-grey);
}

.ts-anchor .ant-anchor-link,
.ts-anchor-noaffix .ant-anchor-link {
	border: 1px solid transparent;
	padding-right: 16px;
	padding-left: 16px;
}

.ts-anchor .ant-anchor-link:hover,
.ts-anchor-noaffix .ant-anchor-link:hover {
	border: 1px solid var(--primary-color);
}

.ts-anchor .ant-anchor-ink::before,
.ts-anchor-noaffix .ant-anchor-ink::before {
	background-color: transparent;
}

.ts-anchor .ant-anchor-ink-ball.visible,
.ts-anchor-noaffix .ant-anchor-ink-ball.visible {
	display: none;
}

.ts-anchor .ts-anchor-body,
.ts-anchor-noaffix .ts-anchor-body {
	background-color: var(--white);
	border: 1px solid #ddd;
	opacity: 0.9;
}
/* .ts-anchor-noaffix .ts-anchor-body {
    margin-top: 8px;
} */

.ts-anchor.ts-anchor-right .ts-anchor-body,
.ts-anchor.ts-anchor-calc-right .ts-anchor-body {
	padding-left: 8px;
}

.ts-anchor-icon {
	/* color: var(--brand-primary-shade-dark); */
	color: var(--primary-color);
	font-size: 20px;
	margin: 4px;
}

.ts-header .ts-anchor-icon {
	margin: 0;
}