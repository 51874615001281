/* TsCard */

.ts-card {
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.32);  
	background-color: var(--white);
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	border-radius: 2px;
}

.ts-card.expanded {
	display: flex;
}

.ts-card.ts-card-hoverable {
	cursor: pointer;
}

.ts-card .ant-card-card-bordered {
	border: 1px solid #ddd; 
	border-radius: 0px;    
}


.ts-card .ts-card-head-title {    
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	padding: 0px 24px;
	min-height: 48px;
	padding-top: 22px;
	font-family: var(--font-family-header-label);
	font-size: 24px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	color: var(--primary-color-dark);
}

.ts-card .ts-card-body {
	padding: 6px 24px 16px 24px;    
	line-height: 1.71;
}

.ts-card.expanded .ts-card-body {
	padding: 16px 24px 24px 24px;
}

.ts-card-selected {
	box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.32) !important;
	border-style: solid !important;
	border-width: 1px;
	border-bottom-width: 3px !important;
	border-image-source: linear-gradient(336deg, var(--cyan-progress), var(--cerulean)) !important;
	border-image-slice: 1 !important;
	box-shadow: inset 5px  rgba(0, 0, 0, 0.32)  !important;
}

.ts-card .ts-card-actions {
	display: flex;
	justify-content: space-between;
	padding: 0 8px;
	background-color: var(--pale-grey);
}

.ts-card.expanded .ts-card-actions {
	display: block;
	position: relative;
	height: 100%;
}

.ts-card .ts-card-actions .ts-icon {
	width: 48px;
	height: 48px;
	font-size: 16px;
	line-height: 48px;
	text-align: center;
}


.ts-card.expanded .ts-card-actions .ts-card-actions-right {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 8px;
}

.ts-card.expanded > div:not(:first-child) {
	background-color: var( --pale-grey);
}

.ts-card .ts-card-actions .ts-button {
	padding: 8px 0;
}

.ts-card .ts-card-actions .ant-btn{
	background-color: transparent;

}

.ts-card:not(.expanded) .ts-card-actions-left > .ts-button, .ts-card:not(.expanded) .ts-card-actions-right > .ts-button {
	margin-right: 8px;
}

.ts-card:not(.expanded) .ts-card-actions-right > .ts-button:last-child {
	margin-right: 0px;
}

.ts-card .ts-card-list {
	margin-left: -24px;
	margin-right: -24px;
	padding-bottom: 16px;
	color: var( --dark-blue-grey);
}

.ts-card .ts-card-list .ant-list-item:not(:last-child) {
	border-bottom: 1px solid var( --dark-blue-grey);
}

.ts-card .ts-card-list .ant-list-item:last-child {
	border-bottom: 0;
}

.ts-card .ant-list-item {
	padding-left: 24px;
	padding-right: 24px;
}

.ts-card .ts-card-table {
	padding-bottom: 16px;
	margin-left: -8px;
	margin-right: -8px;
}

.ts-card .ts-card-table.ts-card-table-big-totals {
	padding-bottom: 0;    
}

.ts-card .ts-card-table * {
	background-color: transparent !important;
}

.ts-card .ts-card-table.ts-card-table-with-line {
	padding-bottom: 0px;
}

.ts-card  .ts-card-table .ReactTable .rt-tbody {
	overflow: visible;
}

.ts-card .ts-card-table .ts-table-top,
.ts-card .ts-card-table .ts-table-down {
	display: none;    
}

.ts-card .ts-card-table.ts-card-table-with-line .ReactTable .rt-tbody .rt-tr-group:last-child {
	margin-left: -16px;
	margin-right: -16px;
	padding-left: 16px;
	padding-right: 16px;
	border-top: 1px solid var(--btn-color-disabled);
	margin-top: 8px;
	padding-top: 8px;
}

.ts-card .ts-card-table .ts-table-td-content {
	font-weight: bold;
}

.ts-card .ts-card-table.ts-card-table-big-totals .ReactTable .rt-tbody .rt-tr-group .rt-td:not(:first-child) {
	font-size: 32px;
	font-weight: bold;
}

.ts-card .ts-card-table.ts-card-table-big-totals .ReactTable .rt-tbody .rt-tr-group .rt-td:first-child  .ts-table-td-content {
	font-size: 16px;
	font-weight: normal;
	text-align: right;
}

.ts-card .ts-card-table .ts-table .ReactTable .rt-thead.-header .rt-th * {
	font-family: var(--font-family-header-label);
	font-size: 16px;
	font-weight: 600;
}

.ts-card .ts-card-table .rt-td, 
.ts-card .ts-card-table .ReactTable .rt-tbody .rt-tr-group > .rt-tr {
	border: 0 !important;    
}

.ts-card .ts-card-table .more-link .ts-icon {
	margin-right: 0.8rem;
}

.ts-card .ts-card-table .more-link * {    
	font-size: 1rem;
}

.ts-card .ts-card-table .ReactTable .rt-tbody .rt-tr:hover {
	box-shadow: none !important;
}

.ts-card .ts-card-table .ReactTable .rt-table > .rt-thead.-header.ts-no-filters, 
.ts-card .ts-card-table .ReactTable .rt-table > .rt-thead.-header:not(.ts-no-filters) + .rt-thead.-filters {
	box-shadow: none !important;
}

.ts-card .ts-card-table .ReactTable .rt-tbody .rt-tr-group:hover > .rt-tr, 
.ts-card .ts-card-table .ReactTable .rt-tbody .rt-tr-group.ts-table-checked-group > .rt-tr, 
.ts-card .ts-card-table .ReactTable .rt-tbody .rt-tr-group.ts-table-selected-group > .rt-tr {
	border: 0 !important;
}

.ts-card .ts-card-progress .ant-progress-inner {
	vertical-align: bottom;
	background-color: rgba(159, 159, 159, 0.4) !important;
	border-radius: 0% !important;
}

.ts-card .ts-card-progress .ant-progress-bg {
	background-image: var(--primary-background) !important;
}

.ts-card .ts-card-progress .ant-progress-status-success .ant-progress-bg {
	background-image: var(--success-color) !important;
}

.ts-card .ts-card-progress .ant-progress-status-exception .ant-progress-bg {
	background-image: var(--danger-color) !important;
}

.ts-card  .ts-card-progress-header {
	padding: 6px 24px 0px 24px;
	display: flex;
	justify-content: space-between;    
}
    
.ts-card  .ts-card-progress-percent {
	color: var(--primary-color);
}

.ts-card .ts-card-left-container {
	flex-grow: 1;
	width: 328px; 
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.ts-card.ts-card-bordered:hover {
	outline: solid 1px var(--primary-color);
	border: 1px solid var(--primary-color);
}

.ts-card.ts-card-bordered {
	-webkit-box-shadow: rgba(0,0,0,0.32) 0 2px 8px 0;
	box-shadow: 0 2px 8px 0 rgba(0,0,0,0.32);    
	border: 1px solid #e8e8e8;    
}

.ts-card .ts-card-right-container {
	flex-grow: 1;
}

.ts-card .ts-card-right-container .ts-card-body {
	padding: 16px 32px 24px 32px;
}

.ts-card .ts-card-description {    
	font-size: 14px;
	line-height: 1.71;
	font-weight: normal;
	letter-spacing: 0.25px;
	color: var(--quick-silver);
}

.ts-card .ts-button > button > div {
	font-size: 16px;
	font-weight: 500;
}

.ts-card .ts-card-cover img {
	border-radius: 2px 2px 0 0;
}

.ts-card .ts-card-cover > * {
	display: block;
	width: 100%;
}

.ts-card .ts-card-head-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.ts-card .ts-card-extra {
	float: right;
	margin-left: auto;
	padding: 0px 24px;
	padding-top: 26px;
	min-height: 48px;
	color: rgba(0,0,0,0.65);
	font-weight: normal;
	font-size: 14px;
}