/* Toolbar */

.ts-toolbar {    
	background-color: var(--white-smoke);
	box-shadow: 0 -2px 8px 0 rgba(21, 41, 53, 0.32);
	padding: 4px;
	position: fixed;
	bottom: 0px;
	z-index: 999;
	left: 0px;
	width: 100%;
	color: var(--primary-color);
	font-size: 15px;
	font-family: Roboto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 15px;
	padding-left: 15px;
	min-height: var(--toolbar-height);
}

.ts-toolbar-dx {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.ts-toolbar-sx {  
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.ts-toolbar-button-label-red .ts-label {
	color: var(--maximum-red);
}

.ts-toolbar-button {
	cursor: pointer; 
	text-align: center;   
	min-width: 80px;
	font-weight: var(--font-weight);
	height: var(--button-height);
	margin: 0 8px 0 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid transparent;
}

.ts-toolbar-button:not(.ts-toolbar-button-disabled):hover {
	border: 1px solid var(--primary-color);
}

.ts-toolbar-button-label-red,
.ts-toolbar-button-label {
	width: auto;
	padding-right: 8px;
	padding-left: 8px;
}

.ts-toolbar-button-label .ts-label {
	color: var(--primary-color);
	font-weight: 500;
	cursor: pointer; 
}

.ts-toolbar-button.ts-toolbar-button-disabled {
	cursor: not-allowed;
	color: var(--btn-color-disabled);       
}

.ts-toolbar-button.ts-toolbar-button-disabled .ts-toolbar-button-label .ts-label {
	color: var(--btn-color-disabled); 
	cursor: not-allowed;
}

/* primary button */

.ts-toolbar-button.ts-primary {
	background-image: var(--primary-background);
	border: transparent;
	border-radius: 2px;
}

.ts-toolbar-button.ts-primary .ts-toolbar-button-label .ts-label {
	color: var(--white);
}

.ts-toolbar-button.ts-primary:hover {
	background-color: var(--cyan-progress);
	background-image: none;
	border: transparent;
	border-radius: 2px;
}

.ts-toolbar-button.ts-toolbar-button-disabled.ts-primary {
	background-color: var(--columbia-blue);
	background-image: none;
}

/* secondary button */

.ts-toolbar-button.ts-default {
	background: transparent;
	color: var(--primary-color);
	border: 1px solid var(--primary-color);
	border-radius: 2px;
}

.ts-toolbar-button.ts-default:hover {
	background: var(--primary-color);
}

.ts-toolbar-button.ts-default:hover .ts-toolbar-button-label .ts-label {
	color: var(--white);
}

/* secondary button disabled */

.ts-toolbar-button.ts-toolbar-button-disabled.ts-default {
	background: transparent;
	border: 1px solid var(--columbia-blue);
	border-radius: 2px;
}

.ts-toolbar-button-disabled.ts-default .ts-toolbar-button-label .ts-label
.ts-toolbar-button-disabled.ts-default:hover .ts-toolbar-button-label .ts-label,
.ts-toolbar-button.ts-toolbar-button-disabled.ts-default .ts-toolbar-button-label .ts-label,
.ts-toolbar-button.ts-toolbar-button-disabled.ts-tertiary-danger .ts-toolbar-button-label .ts-label {
	color: var(--columbia-blue);
}

/* tertiary button danger */

/* .ts-toolbar-button.ts-tertiary-danger { */
/* border: 1px solid var(--danger-color); */
/* } */

.ts-toolbar-button.ts-tertiary-danger .ts-toolbar-button-label .ts-label {
	color: var(--danger-color);
}

.ts-toolbar-button.ts-tertiary-danger:hover {
	border: 1px solid var(--danger-color);
}

.ts-toolbar-button-disabled.ts-tertiary-danger:hover {
	border: transparent;
}
