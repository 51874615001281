.ts-table .ReactTable,
.ts-table .ReactTable .rt-thead .rt-th,
.ts-table .ReactTable .rt-tbody,
.ts-table .ReactTable .rt-tbody .rt-tr-group,
.ts-table .ReactTable .rt-tbody .rt-td,
.ts-table .ReactTable .rt-tfoot .rt-td {
	border: none;
}

.ts-table .ReactTable {
	background-color: var(--white);
}

.ts-table .ReactTable .rt-table {
	overflow: initial;
}

.ts-table .ReactTable .rt-noData {
	display: none;
}

.ts-table .ReactTable .rt-thead,
.ts-table .ReactTable .rt-tbody,
.ts-table .ReactTable .rt-tfoot {
	overflow: hidden;
}

.ts-table .ReactTable .rt-thead .ts-table-th > div {
	height: 100%;
}

.ts-table .ReactTable .rt-thead .ts-truncate,
.ts-table .ReactTable .rt-tbody .ts-truncate,
.ts-table .ReactTable .rt-tfoot .ts-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ts-table .ReactTable .rt-tbody .ts-truncate,
.ts-table .ReactTable .rt-tfoot .ts-truncate {
	cursor: default;
}

.ReactTable .rt-thead.-header {
	box-shadow: none;
}

.ts-table .ReactTable .rt-thead.-headerGroups {
	background: var(--white);
	border-bottom: none;
	z-index: 3;
}

.ts-table .ReactTable .rt-thead.-headerGroups .ts-table-multi-header {
	background: var(--white);
}

.ts-table .ReactTable .rt-table > .rt-thead.-header:not(.ts-no-filters) {
	background: var(--white);
	z-index: 4;
}

.ts-table .ReactTable .rt-table > .rt-thead.-header.ts-no-filters {
	background: var(--white);
}

.ts-table .ReactTable .rt-table > .rt-thead.-header.ts-no-filters.ts-inner-column {
	background: var(--white-smoke);
}

.ts-table .ReactTable .rt-table > .rt-thead.-header.ts-no-filters, 
.ts-table .ReactTable .rt-table > .rt-thead.-header:not(.ts-no-filters) + .rt-thead.-filters {
	z-index: 5;
}

.ts-table .ReactTable .rt-table > .rt-thead.-header.ts-no-filters + .rt-thead.-filters {
	display: none;
}

.ts-table .ReactTable .rt-table > .rt-thead.-header.ts-no-filters.ts-no-data {
	border-bottom: 1px solid var(--quick-silver);
}

.ts-table .ReactTable .rt-table > .rt-thead.-filters {
	z-index: 5;
}

.ts-table .ReactTable .rt-table > .rt-thead .ts-column-header-resizable:hover {
	border: 1px solid var(--primary-color);
}

.ts-table .ReactTable .rt-table > .rt-thead .rt-resizer {
	width: 3px;
	right: -1px;
}

.ts-table .ReactTable .rt-table > .rt-thead .rt-resizer:hover {
	background-color: var(--cerulean);
}

.ts-table .ReactTable .ts-resize-line {
	border-right: 2px solid var(--cerulean) !important;
}

.ts-table .ReactTable .rt-table > .rt-thead .ts-resize-line .rt-resizer {
	background-color: var(--cerulean);
}

.ts-table .ReactTable .ts-resize-line.ts-table-cell {
	margin-left: -1px;
}

.ts-table .ReactTable .rt-tbody {
	overflow: hidden;
}

.ts-table .ReactTable .-sort-asc,
.ts-table .ReactTable .-sort-desc {
	box-shadow: none !important;
}

.ts-table .ReactTable .rt-thead.-header .ts-table-th .rt-resizable-header-content {
	height: 100%;
}

.ts-table .ReactTable .rt-thead .rt-th,
.ts-table .ReactTable .rt-tbody .rt-td {
	font-family: Roboto;
	font-size: var(--font-size);
	font-style: normal;
	font-stretch: normal;
	line-height: 1.29;
	letter-spacing: normal;
	border-color: transparent;
	padding: 0;
}

.ts-table .ReactTable .rt-tbody .rt-td {
	color: var(--dark-blue-grey);
}

.ts-table .ReactTable .rt-thead.-headerGroups .rt-th,
.ts-table .ReactTable .rt-thead.-header .rt-th {
	font-weight: 500;
	color: var(--primary-color-dark);
}

.ts-table .ReactTable .rt-thead.-filters {
	background-color: var(--white-smoke);
	border-bottom: none;
}

.ts-table .ReactTable .rt-tfoot {
	-webkit-box-shadow: none;
	box-shadow: none;
	background-color: var(--azure);
	font-weight: 500;
	color: var(--primary-color-dark);
}

/* + Filters + */

.ts-table .ReactTable .rt-thead .ts-table-th .ts-table-th-filter {
	width: 100%;
	height: 100%;
}

.ts-table .ReactTable .rt-thead.-filters input, 
.ts-table .ReactTable .rt-thead.-filters select {
	border: 1px solid transparent;
	background: transparent;
	padding: 0;
	border-radius: 0;
	font-weight: normal;
	outline: none;
}

/* filter input */

.ts-table .ReactTable .rt-thead .ts-table-th .ts-table-th-filter .ts-input input {
	background-color: transparent;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	padding: 4px 11px;
	padding-right: 40px;
}

.ts-table .ReactTable .rt-thead .ts-table-th .ts-table-th-filter .ts-input input:hover,
.ts-table .ReactTable .rt-thead .ts-table-th .ts-table-th-filter .ts-input input:focus {
	border-color: var(--title-color);
	background-color: transparent;
}

/* filter number */

.ts-table .ReactTable .rt-thead.-filters .ts-input-number .ts-number {
	border-color: transparent;
	padding-right: 40px;
}

.ts-table .ReactTable .rt-thead.-filters .ts-input-number .ts-number:hover,
.ts-table .ReactTable .rt-thead.-filters .ts-input-number .ts-number:focus {
	border: 1px solid var(--primary-color);
}

/* filter select */

.ts-table .ReactTable .rt-thead.-filters .ts-select .ant-select-selection--single {
	border-color: transparent;
}

.ts-table .ReactTable .rt-thead .ts-table-th .ts-table-th-filter .ts-select .ant-select-arrow {
	color: var(--title-color);
}

.ts-table .ReactTable .rt-thead.-filters .ts-select .ant-select-selection--single:hover {
	border-color: var(--title-color);
	background-color: transparent;
}

.ts-table-filter-select-dropdown.ts-select-dropdown .ant-select-dropdown-menu-item {
	height: var(--input-height);
}

.ts-table-filter-select-dropdown.ts-select-dropdown .ant-select-dropdown {
	border-radius: 0px;
}

.ts-table-filter-select-dropdown.ts-select-dropdown .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
	background-color: transparent;
}

/* filter date */

.ts-table .ReactTable .rt-thead .ts-table-th .ts-table-th-filter .ts-picker .ts-input .ant-input-wrapper.ant-input-group .ant-input-group-addon {
	color: var(--title-color);
}

.ts-table .ReactTable .rt-thead .ts-table-th .ts-table-th-filter .ts-picker .ts-picker-datetime,
.ts-table .ReactTable .rt-thead .ts-table-th .ts-table-th-filter .ts-picker .ts-picker-time {
	visibility: hidden;
} 

/* - Filters - */

.ts-table.ts-table-small .ReactTable .rt-thead,
.ts-table.ts-table-small .ReactTable .rt-thead .rt-tr,
.ts-table.ts-table-small .ReactTable .rt-tbody .rt-tr {
	height: var(--small-height);
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}

.ts-table.ts-table-normal .ReactTable .rt-thead,
.ts-table.ts-table-normal .ReactTable .rt-thead .rt-tr,
.ts-table.ts-table-normal .ReactTable .rt-tbody .rt-tr {
	height: var(--normal-height);
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}

.ts-table.ts-table-large .ReactTable .rt-thead,
.ts-table.ts-table-large .ReactTable .rt-thead .rt-tr,
.ts-table.ts-table-large .ReactTable .rt-tbody .rt-tr {
	height: var(--large-height);
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */
}

.ts-table .ReactTable .rt-tbody .rt-tr-group:not(.ts-table-selected-group):not(.ts-table-editated-group):not(.ts-table-invalid-group):not(:hover) > .rt-tr {
	border-top: 1px solid var(--quick-silver);
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	border-bottom: none;
	z-index: 1;
}

.ts-table .ReactTable .rt-tbody .rt-tr-group:hover > .rt-tr, 
.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-selected-group > .rt-tr,
.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-editated-group > .rt-tr {
	border-top: 1px solid var(--primary-color);
	border-left: 1px solid var(--primary-color);
	border-right: 1px solid var(--primary-color);
	color: var(--text01);
	z-index: 1;
}

.ts-table .ReactTable .rt-tbody .rt-tr-group > .ts-expanded-component {
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
}

.ts-table .ReactTable .rt-tbody .rt-tr-group:hover > .ts-expanded-component, 
.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-selected-group > .ts-expanded-component,
.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-editated-group > .ts-expanded-component {
	border-left: 1px solid var(--primary-color);
	border-right: 1px solid var(--primary-color);
	z-index: 1;
}

.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-invalid-group > .rt-tr {
	border-top: 1px solid var(--danger-color) !important;
	border-left: 1px solid var(--danger-color) !important;
	border-right: 1px solid var(--danger-color) !important;
}

.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-invalid-group > .ts-expanded-component {
	border-left: 1px solid var(--danger-color) !important;
	border-right: 1px solid var(--danger-color) !important;
}

.ts-table .ReactTable .rt-tbody .rt-tr-group:not(.ts-table-selected-group):not(.ts-table-invalid-group):not(.ts-table-editated-group):last-child:not(:hover) > .rt-tr {
	border-bottom: 1px solid var(--quick-silver);
}

.ts-table .ReactTable .rt-tbody .rt-tr-group:last-child:hover > .rt-tr,
.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-selected-group:last-child > .rt-tr,
.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-editated-group:last-child > .rt-tr {
	border: 1px solid var(--primary-color);
	color: var(--dark-blue-grey);
	z-index: 1;
}

.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-invalid-group:last-child > .rt-tr {
	border: 1px solid var(--danger-color) !important;
}

.ts-table .ReactTable .rt-tbody .rt-tr-group:hover + div > .rt-tr, 
.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-selected-group + div > .rt-tr, 
.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-editated-group + div > .rt-tr,
.ts-table .ReactTable .rt-tbody .rt-tr-group:hover + div.rt-tr-group:not(.ts-table-selected-group):not(.ts-table-invalid-group):not(.ts-table-editated-group):not(:hover) > .rt-tr,
.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-selected-group + div.rt-tr-group:not(.ts-table-selected-group):not(.ts-table-invalid-group):not(.ts-table-editated-group):not(:hover) > .rt-tr, 
.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-editated-group + div.rt-tr-group:not(.ts-table-selected-group):not(.ts-table-invalid-group):not(.ts-table-editated-group):not(:hover) > .rt-tr {
	border-top: 1px solid var(--primary-color);
}

.ts-table .ReactTable .rt-tbody .rt-tr-group.ts-table-invalid-group + div > .rt-tr {
	border-top: 1px solid var(--danger-color) !important;
}

.ts-table .ReactTable .rt-tfoot .rt-tr {
	border-bottom: 1px solid var(--quick-silver);
}

.ts-table .ReactTable .rt-thead .rt-th .ts-table-th-content,
.ts-table .ReactTable .rt-tbody .rt-td .ts-table-action-content,
.ts-table .ReactTable .rt-tbody .rt-td .ts-table-td-content,
.ts-table .ReactTable .rt-tfoot .rt-td .ts-table-action-content,
.ts-table .ReactTable .rt-tfoot .rt-td .ts-table-td-content {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 0 8px;
}

.ts-table .ReactTable .rt-tbody .rt-td .ts-table-td-content > div,
.ts-table .ReactTable .rt-tbody .rt-td .ts-table-action-content > div,
.ts-table .ReactTable .rt-tfoot .rt-td .ts-table-td-content > div,
.ts-table .ReactTable .rt-tfoot .rt-td .ts-table-action-content > div {
	width: 100%;
}

.ts-table .ReactTable .rt-tbody .rt-tr .rt-td.ts-cell-no-fixed-first, 
.ts-table .ReactTable .rt-tbody .rt-tr .rt-td.ts-cell-fixed-separator,
.ts-table .ReactTable .rt-tfoot .rt-tr .rt-td.ts-cell-no-fixed-first, 
.ts-table .ReactTable .rt-tfoot .rt-tr .rt-td.ts-cell-fixed-separator {
	box-shadow: inset 8px 0 8px -8px rgb(157, 166, 173);
	-webkit-box-shadow: inset 8px 0 8px -8px rgb(157, 166, 173);
	z-index: 1 !important;
}

.ts-table .ReactTable .rt-tbody .rt-tr .rt-td.ts-cell-editable {
	background-color: var(--white-smoke);
}

.ts-table .ReactTable .rt-tbody .rt-td.ts-table-action .ts-table-actions-btn {
	cursor: pointer;
	font-size: var(--large-font-size);
	color: var(--btn-color-disabled);
}

.ts-table .ReactTable .rt-tbody .rt-td.ts-table-action .ts-table-actions-btn-2x {
	cursor: pointer;
	font-size: var(--xxx-large-font-size);
	color: var(--btn-color-disabled);
}

.ts-table .ReactTable .rt-tbody .rt-tr .rt-td.ts-cell-editable .ts-checkbox {
	display: flex;
	align-items: center;
}

.ts-table .ReactTable .rt-tbody .rt-tr .rt-td.ts-cell-editable .ts-checkbox > label {
	margin: auto;
}

.ts-table .ReactTable .rt-tbody .rt-td.ts-table-action .ts-table-actions-btn.ts-table-action-rightpanel {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.ts-tooltip-table-single-action.ts-tooltip-overlay .ant-popover-inner-content {
	background-color: var(--primary-color-dark);
	color: var(--white);
	border-radius: 2px;
	border: solid 1px var(--primary-color-dark);
}

.ts-tooltip-table-single-action.ts-tooltip-overlay.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
	border-bottom-color: var(--primary-color-dark);
	border-right-color: var(--primary-color-dark); 
}

.ts-table .ReactTable .rt-tbody .rt-td.ts-table-other-actions .ts-table-action-content,
.ts-table .ReactTable .rt-tbody .rt-td.ts-table-other-actions .ts-table-action-content * {
	padding: 0;
	height: 100%;
	width: 100%;
}

.ts-table .ReactTable .rt-tbody .rt-td.ts-table-other-actions .ts-table-action-content > div {
	padding: 8px 0;
}

.ts-table .ReactTable .rt-tbody .ts-table-info-row {
	position: absolute;
	background: radial-gradient(circle, rgba(255,255,255,0.9) 20%, rgba(242,245,248,0.5) 100%);
	z-index: 1;
}

.ts-table .ReactTable .rt-tbody .ts-table-info-row > div {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ts-table .ReactTable .rt-tbody .ts-table-info-row > div .ts-table-info-standard {
	width: 300px; 
	background-color: var(--white);
	display: flex;
	justify-content: center;
}

.ts-table .ReactTable .rt-tbody .ts-table-info-row > div .ts-table-info-standard .ts-label,
.ts-table .ReactTable .rt-tbody .ts-table-info-row > div .ts-table-info-standard .ts-icon {
	color: var(--dark-imperial-blue);
	cursor: default;
}

/* Scroll Columns/Page */

.ts-table-scroll-page-left,
.ts-table-scroll-column-left,
.ts-table-scroll-page-right,
.ts-table-scroll-column-right {
	/* position: absolute; */
	z-index: 5 !important;
	position: fixed;
}

.ts-table-scroll-column-right > .ts-button,
.ts-table-scroll-column-left > .ts-button,
.ts-table-scroll-page-right > .ts-button,
.ts-table-scroll-page-left > .ts-button {
	display: flex;
	align-items: center;
	width: 32px;
	height: 32px;
}

.ts-table-scroll-column-right > .ts-button > button.ant-btn,
.ts-table-scroll-column-left > .ts-button > button.ant-btn,
.ts-table-scroll-page-right > .ts-button > button.ant-btn,
.ts-table-scroll-page-left > .ts-button > button.ant-btn {
	transition: transform 0.5s;
	width: 32px;
	height: 32px;
	padding: 0;
	text-shadow: 1px 1px var(--primary-color);
	line-height: 33px;
}

.ts-table-scroll-column-right > .ts-button > button.ant-btn:disabled,
.ts-table-scroll-column-left > .ts-button > button.ant-btn:disabled,
.ts-table-scroll-page-right > .ts-button > button.ant-btn:disabled,
.ts-table-scroll-page-left > .ts-button > button.ant-btn:disabled {
	text-shadow: none;
}

.ts-table-scroll-page-right > .ts-button > button.ant-btn.ant-btn-lg:focus,
.ts-table-scroll-page-left > .ts-button > button.ant-btn.ant-btn-lg:focus,
.ts-table-scroll-column-right > .ts-button > button.ant-btn.ant-btn-lg:focus,
.ts-table-scroll-column-left > .ts-button > button.ant-btn.ant-btn-lg:focus {
	border: none;
} 

.ts-table-scroll-page-right > .ts-button > button[ant-click-animating-without-extra-node='true']::after,
.ts-table-scroll-page-left > .ts-button > button[ant-click-animating-without-extra-node='true']::after,
.ts-table-scroll-column-right > .ts-button > button[ant-click-animating-without-extra-node='true']::after,
.ts-table-scroll-column-left > .ts-button > button[ant-click-animating-without-extra-node='true']::after {
	display: none;
} 

/* extra info */

.ts-meta, .ts-header-meta {
	display: none;
} 

.ts-keyrow, .ts-header-keyrow {
	position: absolute;
	z-index: -1 !important;
	opacity: 1;
} 

.ts-table .ReactTable .ts-input-readonly input.ant-input.ant-input-disabled,
.ts-table .ReactTable .ts-input-number.ts-number-readonly .ts-number {
	background-color: transparent;
}

.ts-table-container .ts-selected-rows .ts-label,
.ts-table-container .ts-selected-rows .ts-icon {
	font-weight: 500;
}

.ts-table-container .ts-table-add-row-button {
	float: left; 
	padding: 4px 8px; 
}

.ts-table-container .ts-table-add-row-button .add-row {
	color: var(--primary-color);
}

.ts-table-container .ts-table-add-row-button .ts-label.add-row {
	font-weight: 500;
	cursor: inherit;
}

.ts-table .ts-expanded-component {
	padding-left: 40px;
}

.ts-table .ts-icon-expanded {
	font-family: FontAwesome5Pro-Regular;
	font-size: 16px;
	font-weight: normal;
	height: 40px;
	letter-spacing: 0px;
	line-height: 15px;
	text-align: center;
	width: 40px;
	display: inline-grid;
	align-items: center;
}

.ts-table .ReactTable .rt-thead .rt-th .ts-table-th-content .ts-icon-lock,
.ts-table .ReactTable .rt-thead .rt-th .ts-table-th-content .ts-icon-lock > i {
	width: 16px;
	cursor: default;
}

/* colorazione icone barra ricerca */

.ts-table-th-filter i.ts-icon:before,
.ts-table-th-filter i.anticon.anticon-down.ant-select-arrow-icon > svg {
	color: var(--cerulean);
	font-weight: 400;
	font-size: var(--large-font-size);
}

/* fixed column when expandable */

.ts-table .ReactTable .rt-tbody .rt-tr .rt-td.ts-cell-before-column-fixed.ts-table-cell.rt-expandable {
	box-shadow: inset -8px 0 8px -8px rgb(157, 166, 173);
}
/*  */

.ts-table-container .ts-table-nodata-icon .ts-icon {
	cursor: default;
}

.ts-table-container .ts-table-nodata-label label.ts-label {
	color: var(--dark-imperial-blue);
	font-size: 16px;
	font-weight: 600;
	font-family: Cairo;
}

.ts-table-container .ts-table-nodata-label-descr label.ts-label {
	color: var(--dark-imperial-blue);
	font-size: 12px;
}

.ts-table-container .ts-table-nodata-icon .ts-icon.fontawe-icon-blue::before {
	color: var(--dark-imperial-blue);
}

.ts-table-container .ts-table-nodata-icon .ts-icon.fontawe-icon::before {
	color: var(--white-smoke);
}

.ts-table-container .ts-table-top {
	margin-top: 8px;
}

.ts-table-container .ts-table-down {
	margin-bottom: 8px;
}

.ts-table-container .ts-table-top .ts-table-top-right {
	float: right;
	text-align: end;
	height: 40px;
	position: absolute;
	right: 0;
	bottom: 0;
}

.ts-table-container .ts-table-down .ts-table-down-right {
	float: right;
	text-align: end;
	min-width: 300px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.ts-table-container .ts-table-top .ts-table-top-description {
	float: left;
	min-height: 40px;
	width: 100%;
}

.ts-table-container .ts-table-top-left {
	position: absolute;
	float: left;
	width: 35%;
}

.ts-table-container .ts-table-top-left > div {
	float: left;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ts-table-container .ts-table-top-left > .ts-contextActions .ts-tooltip,
.ts-table-container .ts-table-top-left > .ts-contextActions .ts-tooltip .ts-tooltip-opened {
	height: 100%;
}

.ts-table-container .ts-table-top-left > .ts-contextActions .ts-contextActions-content,
.ts-table-container .ts-table-top-left > .ts-group-actions {
	padding: 0 5px;
	position: relative;
}

.ts-table-container .ts-table-top-left > .ts-contextActions .ts-contextActions-content {
	width: 105px;   
}

.ts-table-container .ts-table-top-left > .ts-contextActions .ts-contextActions-content > div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ts-table-container .ts-table-top-left > .ts-contextActions .ts-contextActions-content > div:first-child {
	width: 40px;
	height: 40px;
	float: left;
}

.ts-table-container .ts-table-top-left > .ts-contextActions .ts-contextActions-content > div:last-child {
	width: 55px;
	height: 40px;
	float: right;
}

.ts-table-container .ts-table-top-left > .ts-contextActions .ts-contextActions-content .ts-label {
	color: var(--primary-color);
	cursor: pointer;
}

.ts-table-container .ts-table-top-left .ts-group-actions .ts-reload-action,
.ts-table-container .ts-table-top-left .ts-group-actions .ts-export-action,
.ts-table-container .ts-table-top-left .ts-group-actions .ts-print-action {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ts-table-container .ts-table-top-right > .ts-separator,
.ts-table-container .ts-table-top-left > .ts-separator {
	height: 40px;
	background-color: var(--columbia-blue);
	width: 1px;
}

.ts-table-container .ts-table-top-left .ts-icon {
	font-size: 16px;
}

.ts-table-container .ts-table-top-right {
	position: absolute;
	float: right;
	width: 65%;
}

.ts-table-container .ts-table-top-right .ts-icon {
	font-size: 16px;
}

.ts-table-container .ts-table-top-right > .ts-search-action {
	padding: 0 4px;
	position: relative;
	float: right;
}

.ts-table-container .ts-table-top-right > .ts-search-action > div:first-child {
	width: 90px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-color: var(--input-bordercolor);
	box-sizing: border-box;
	border-width: var(--input-border-width);
	border-radius: var(--input-border-radius);  
	border-style: solid;
}

.ts-table-container .ts-table-top-right > .ts-search-action > div:hover:first-child {
	border-color: var(--primary-color);
}

.ts-table-container .ts-table-top-right > .ts-search-action > div:last-child {
	display: none;
}

.ts-table-container .ts-table-top-right > .ts-search-action > div .ts-icon {
	margin-left: -4px;
}

.ts-table-container .ts-table-top-right > .ts-search-action > div .ts-label {
	margin-left: 8px;
}

.ts-table-container .ts-table-top-right > .ts-search-action .ts-button button.ant-btn.ant-btn-link:hover:not(:disabled) {
	background-color: transparent;
}

.ts-table-container .ts-table-top-right > .ts-search-action.ts-search-action-open {
	z-index: 2;
}

.ts-table-container .ts-table-top-right > .ts-search-action.ts-search-action-open > div:last-child {
	width: 425px;
	height: 40px;
	display: block;
	z-index: 3;
	margin-top: -40px;
	margin-left: -335px;
	position: absolute;
}

.ts-table-container .ts-table-top-right > .ts-search-action.ts-search-action-open .ts-icon.fontawe-icon-dark-blue-grey::before {
	color: var(--primary-color);
}

.ts-table-container .ts-table-top-right > .ts-search-action.ts-search-action-open .ts-search .ts-search-wrapper .ts-search-autocomplete .ant-select-selection {
	background-color: var(--white);
}


.ts-table-container .ts-table-top-right .ts-models {
	float: right;
	width: 270px;
}

.ts-table-container .ts-table-top-right .ts-models .ts-models-list {
	width: 185px;
	float: right;
}

.ts-table-container .ts-table-top-right .ts-models .ts-models-filters,
.ts-table-container .ts-table-top-right .ts-models .ts-models-columns {
	width: 40px;
	height: 40px;
	float: right;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--input-backgroud);
	border-color: var(--input-bordercolor);
	box-sizing: border-box;
	border-width: var(--input-border-width);
	border-radius: var(--input-border-radius);  
	border-style: solid;
}

.ts-table-container .ts-table-top-right .ts-models .ts-models-filters:hover,
.ts-table-container .ts-table-top-right .ts-models .ts-models-columns:hover {
	border-color: var(--primary-color);
}

.ts-table-container .ts-table-top-right .ts-models .ts-models-list .ant-select-arrow .ant-select-arrow-icon {
	color: var(--primary-color);
}

.ts-table-container .ts-table-top-right .ts-table-pagination-up {
	height: 40px;
	float: right;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 16px;
	padding-right: 8px;
}

/* filters */

.ts-table-container .ts-search-filters .ts-button {
	float: right;
}

.ts-table-container .ts-search-filters .ts-filter-tag-blue {
	background-color: var(--azure);
	float: right;
	padding: 4px 8px;
	border: none;
	color: var(--dark-imperial-blue);
	height: 24px;
	margin-top: 4px;
}

.ts-table-container .ts-search-filters .ts-filter-tag-orange {
	background-color: var(--crayola);
	float: right;
	padding: 4px 8px;
	border: none;
	color: var(--dark-imperial-blue);
	height: 24px;
	margin-top: 4px;
}

.ts-table-container .ts-search-filters .ant-tag:hover {
	opacity: 1;
}

.ts-table-container .ts-search-filters .ant-tag .anticon-close,
.ts-table-container .ts-search-filters .ant-tag .anticon-close:hover {
	color: var(--dark-imperial-blue);
	font-weight: 400;
}

.ts-table-container .ts-search-filters .ts-search-filters .ts-button {
	float: right;
}

.ts-table-container .ts-search-filters .ts-filter-internal-tag {
	height: 18px;
	font-size: 10px;
	line-height: 18px;
	padding: 0;
	background-color: var(--input-highlighted);
	color: var(--input-addon-color);
}

/******************** horizontal scroll table ***************************/

.ts-table.ts-table-hscroll .ts-table-virtual-scroll {
	height: 9px;
	overflow-x: auto;
	overflow-y: hidden;
	display: none;
	margin-top: 8px;
	background-color: var(--white);
}

.ts-table.ts-table-hscroll .ts-checkbox-table-selector,
.ts-table.ts-table-hscroll .ts-table-cell.rt-expandable,
.ts-table.ts-table-hscroll .rt-thead.-header .ts-table-th.th-expandable, 
.ts-table.ts-table-hscroll .rt-thead.-header .ts-header-fixed, 
.ts-table.ts-table-hscroll .ts-cell-fixed {
	position: absolute;
	z-index: 5;
	background-color: #FFFFFF;
	height: 40px;
}

.ts-table.ts-table-hscroll .rt-thead.-filters .ts-table-th.th-expandable, 
.ts-table.ts-table-hscroll .rt-thead.-filters .ts-header-fixed {
	position: absolute;
	z-index: 5;
	height: 40px;
}

.ts-table.ts-table-hscroll .ts-checkbox-table-selector,
.ts-table.ts-table-hscroll .ts-table-cell.rt-expandable {
	left: 0;
} 

.ts-table.ts-table-hscroll .ts-checkbox-table-selector + .ts-table-cell.rt-expandable,
.ts-table.ts-table-hscroll .ts-checkbox-table-selector + .ts-table-th.th-expandable {
	left: 40px;
}

.ts-table.ts-table-hscroll .ts-checkbox-table-selector + .ts-table-cell:not(.rt-expandable),
.ts-table.ts-table-hscroll .ts-table-cell.rt-expandable + .ts-table-cell,
.ts-table.ts-table-hscroll .ts-checkbox-table-selector + .ts-table-th:not(.th-expandable),
.ts-table.ts-table-hscroll .ts-table-cell.rt-expandable + .ts-table-th {
	left: 40px;
}

.ts-table.ts-table-hscroll .ts-checkbox-table-selector + .ts-table-cell.rt-expandable + .ts-table-cell,
.ts-table.ts-table-hscroll .ts-checkbox-table-selector + .ts-table-th.th-expandable + .ts-table-th {
	left: 80px;
}

.ts-table.ts-table-hscroll .ts-table-cell-fixed-shadow {
	position: absolute;
	z-index: 5;
	height: 40px;
}

.ts-table.ts-table-hscroll .ts-table-header-action-hscroll,
.ts-table.ts-table-hscroll .ts-table-header-other-actions-hscroll,
.ts-table.ts-table-hscroll .ts-table-action-hscroll,
.ts-table.ts-table-hscroll .ts-table-other-actions-hscroll {
	position: absolute;
	height: 40px;
	z-index: 5;
	background-color: #FFFFFF;
}

.ts-table.ts-table-hscroll .rt-tbody .rt-tr-group:last-child .rt-tr:last-child .ts-checkbox-table-selector,
.ts-table.ts-table-hscroll .rt-tbody .rt-tr-group:last-child .ts-checkbox-table-selector + .ts-table-th.th-expandable,
.ts-table.ts-table-hscroll .rt-tbody .rt-tr-group:last-child .rt-tr:last-child .ts-table-cell.rt-expandable, 
.ts-table.ts-table-hscroll .rt-tbody .rt-tr-group:last-child .rt-tr:last-child .ts-cell-fixed,
.ts-table.ts-table-hscroll .rt-tbody .rt-tr-group:last-child .rt-tr:last-child .ts-table-cell-fixed-shadow,
.ts-table.ts-table-hscroll .rt-tbody .rt-tr-group:last-child .rt-tr:last-child .ts-table-action-hscroll,
.ts-table.ts-table-hscroll .rt-tbody .rt-tr-group:last-child .rt-tr:last-child .ts-table-other-actions-hscroll {
	height: 38px;
}

/************************************************************   */

/**************** Footer ************************************   */

.ts-table .ReactTable .rt-tfoot .rt-td {
	padding: 0;
}

.ts-table .ReactTable .rt-tfoot .rt-td > .ts-footer-cell {
	width: 100%;
	height: 40px;
}
