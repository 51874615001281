.ts-header.ant-layout-header {
	padding: 0px 16px;
	box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);  
	border-bottom: 3px solid var(--primary-color);   
	background-color: var(--white);
	max-width: var(--max-page-width);
	transition: all 0.3s; 
	height: 70px;
	margin: 0 auto;    
	/* width: 90%;  */
	width: var(--page-width);
}

.ts-header-small { 
	height: 48px !important;    
	font-size: 16px;
}

.ts-header .ts-header-arrow {  
	cursor: pointer;
	vertical-align: middle;
	line-height: 70px;
}

.ts-header.ts-header-small .ts-header-arrow  {
	line-height: 48px;
	height: 48px;
	margin-left: auto; /* per mantenere il bottone a dx */
}

.ts-header.ts-header-small .ts-header-div {
	justify-content: flex-start;
}

.ts-header-title, .ts-header-description {
	font-family: var(--font-family-header);  
	align-items: center;
	justify-content: center;
	font-size: var(--font-size-header);
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	/* line-height: 64px; */
}

.ts-header-title {
	color: var(--primary-color-dark);
	text-align: center;
	transition: all 0.5s; 
}

.ts-header-description {
	color: var(--cadet);
	font-size: 12px;
	font-weight: 400;
	font-family: var(--font-family);
	text-align: center;
}

.ts-header-small .ts-header-title {
	text-align: left;
	font-size: 16px;  
}

.ts-header.ts-header-without-description .ts-header-title {
	line-height: 64px;
}

.ts-header-small.ts-header-without-description .ts-header-title {
	line-height: 38px;
}

.ts-header-small .ts-header-description {
	text-align: left;
}

.sticky {
	position: fixed;
	top: 0;
	width: 100%;
	height: 48px;  
	z-index: 100;
	/* font-size: var(--font); */
	text-align: left;
	transition: all 0.3s; 
	transition: position 0.3s; 
}

.ts-header-wrapper {
	width: 100%;
	align-items: center;  
}

.ts-header-wrapper.sticky {
	width: 100%;
}

.ts-header-description .ts-breadcrumb-title.ant-breadcrumb,
.ts-header-description .ts-breadcrumb-title a.ant-breadcrumb-link,
.ts-header-description .ts-breadcrumb-title span.ant-breadcrumb-separator,
.ts-header-description .ts-breadcrumb-title span.ant-breadcrumb-link,
.ts-header-description .ts-breadcrumb-title span.ant-breadcrumb-link > span,
.ts-header-description .ts-breadcrumb-title span.ant-breadcrumb-link > a {
	font-size: 12px;
	/* color: var(--primary-color-dark); */
	font-family: var(--font-family-header);
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: 12px;
	letter-spacing: normal; 
}

.ts-header-description .ts-breadcrumb-title span.ant-breadcrumb-link .ts-breadcrumb-item-href {
	font-weight: 400;
	color: var(--cerulean);
	cursor: pointer;
}

.ts-header-description .ts-breadcrumb-title span.ant-breadcrumb-link > span{
	font-weight: 700; 
	font-style: bold;
	color: var(--dark-imperial-blue);
}

.ts-header-description .ts-breadcrumb-title span.ant-breadcrumb-link > a:focus {
	text-decoration-line: none;
}

/* nuovo layout per gestione bottoni toolbar */

.ts-header-div {
	display: flex;
	justify-content: space-between;
	align-content: center;
}

.ts-header-left {
	min-width: 48px;;
}

.ts-header-right {
	min-width: 48px;
}

/* per visualizzare più bottoni sulla dx */

.ts-header-right.ts-header-arrow {
	display: flex;
	align-content: center;
	justify-content: flex-end;
}

.ts-header .ts-header-div .ts-header-center .ts-header-title .ts-favorite {
	padding-left: 10px;
	display: inline-block;
	font-size: var(--normal-font-size);
	vertical-align: 1px;
}