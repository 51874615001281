.close.page .ts-right-panel-main {
	width: 48px;
	min-width: 48px;
	height: auto;
	z-index: 500;
}

.ts-badge.right-panel .ts-right-panel-error-btn {
	cursor: pointer;
	border: transparent;
	border-right: none;
	border-radius: 0px;
	height: var(--right-panel-header-height);
	width: var(--right-panel-header-height);
	text-align: center;
	display: flex;
}

/* Right Panel */

.ts-right-panel .ant-tabs-content {
	overflow-y: auto;
	padding: 20px !important;
	border-top: 1px solid #c3c3d9;
}

.ts-right-panel-main {
	display: flex;
	position: fixed;
	right: 0;
	top: 0;
	width: auto;
	height: 100%;
	z-index: 2000;
	min-width: 768px;
}

.ts-right-panel {
	float: left;
	/* width: auto; */
	height: 100%;
	/* calc(100% - 126px); */
	position: relative;
	max-width: calc(100% - var(--right-panel-header-height));
	top: 0;
	/* 126px; */
	right: 0px;
	background-color: var(--white);
	z-index: 9;
	box-shadow: -2px 0 8px 0 rgba(21, 41, 53, 0.32);
	width: 100%;
	overflow: hidden;
}

.ts-right-panel-btn-container {
	float: left;
	font-size: var(--font-size-xl);
	position: relative;
	top: 0;
	z-index: 11;
	box-shadow: -2px 0 8px 0 rgba(21, 41, 53, 0.32);
	width: var(--right-panel-header-height);
	border: transparent;
	height: 144px;
}

.dropzone.close {
	position: absolute;
	float: left;
	font-size: var(--font-size-xl);
	z-index: 11;
	width: var(--right-panel-header-height);
	border: transparent;
	height: 164px;
}

.ts-right-panel-btn-container.close.PAGE {
	text-align: center;
	background: var(--white);
	height: 164px;
}

.ts-right-panel-icon-drag-container {
	height: 10px;
	width: 48px;
	display: flex;
	text-align: center;
	background-color: var(--white-smoke);
}

.ts-right-panel-icon-drag {
	width: 48px;
	color: var(--columbia-blue);
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: center;
	align-self: center;
}

.ts-right-panel-icon-drag.close:hover {
	cursor: none;
}

.ts-right-panel-icon-drag-container.open,
.ts-right-panel-icon-drag.open {
	display: none;
}

.ts-right-panel-icon-drag-container:active> .ts-right-panel-icon-hand-active,
.ts-right-panel-icon-drag-container:hover> .ts-right-panel-icon-hand {
	display: block;
	position: absolute;
	cursor: none;
	z-index: 1;
	color: var(--dark-imperial-blue);
	transform: translatex(-10px);
	height: 14px;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
}

.ts-right-panel-icon-drag-container:active> .ts-right-panel-icon-hand {
	display: none;
}

.ts-right-panel-icon-hand-active,
.ts-right-panel-icon-hand {
	display: none;
}

.ts-right-panel-btn-container .ts-button {
	margin: 0 !important;
}

.ts-right-panel-btn-container .ts-button> .ant-btn {
	border: none;
	background-color: var(--white);
	border-radius: 0;
	margin: auto;
}

.ts-right-panel-form-btn-container,
.ts-right-panel-lookup-btn-container,
.ts-right-panel-lookup-error-container {
	background-color: var(--white);
	text-align: center;
	text-align: -webkit-center;
	text-align: -moz-center;
}

.ts-right-panel-close-btn {
	background-color: rgba(242, 245, 248, 1) !important;
	border: transparent !important;
	border-right: 0.5px solid rgba(13, 207, 218, 0.3) !important;
	border-radius: 0px !important;
	height: var(--right-panel-header-height) !important;
	width: var(--right-panel-header-height) !important;
	text-align: center;
}

.ts-right-panel-lookup-btn {
	cursor: pointer;
	border: transparent;
	border-right: none;
	border-radius: 0px;
	height: var(--right-panel-header-height);
	width: var(--right-panel-header-height);
	text-align: center;
	display: flex;
}

.ts-right-panel-info-btn {
	cursor: pointer;
	border: transparent;
	border-right: none;
	border-radius: 0px;
	height: var(--right-panel-header-height);
	width: var(--right-panel-header-height);
	text-align: center;
	display: flex;
}

.ts-right-panel-form-btn {
	cursor: pointer;
	border: transparent;
	border-right: none;
	border-radius: 0px;
	height: var(--right-panel-header-height);
	width: var(--right-panel-header-height);
	text-align: center;
	display: flex;
}

.ts-right-panel-hidden {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0.33s linear, opacity 0.33s linear;
}

.ts-right-panel-visible {
	visibility: visible;
	opacity: 1;
	transition: visibility 0.33s linear, opacity 0.33s linear;
}

.ts-right-panel .ant-tabs-tab {
	width: 315px;
	height: var(--right-panel-header-height);
	font-family: var(--font-family-header);
	font-size: var(--large-font-size);
	font-weight: 600;
	text-align: center;
	font-style: normal;
	line-height: normal;
	color: var(--primary-color-dark);
	background-color: var(--white);
	/* var(--grey03) */
	margin: 0px;
}

.ts-right-panel .ant-tabs-tab-active.ant-tabs-tab:hover {
	color: var(--primary-color);
}

.ts-right-panel .ant-tabs-tab-disabled {
	width: 315px;
	height: var(--right-panel-header-height);
	font-family: Roboto;
	font-size: 24px;
	font-weight: 500;
	line-height: 60px;
	text-align: center;
	color: var(--columbia-blue);
	margin: 0px;
	background-color: var(--white);
}

.ts-right-panel .ant-tabs-ink-bar {
	width: 315px;
	background-color: var(--title-color);
	height: 2px;
}

.ts-right-panel .ant-tabs-bar {
	margin-bottom: 0px;
}

.ts-right-panel-hidden.page {
	visibility: visible;
	opacity: 1;
}

.ts-badge.right-panel .ant-badge-count {
	top: 12px;
	left: -6px;
	right: auto;
}

.ts-right-panel-header {
	border-bottom: 3px solid var(--primary-color);
	background-color: var(--white);
	max-width: var(--max-page-width);
	transition: all 0.3s;
	height: 48px;
	margin: 0 auto;
	top: 0;
	z-index: 100;
	width: 768px;
}

.ts-right-panel-header-title {
	color: var(--primary-color-dark);
	transition: all 0.5s;
	font-family: Cairo;
	font-size: 16px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	height: 48px;
	line-height: 48px;
}

.ts-right-panel-body {
	margin-top: 48px;
	padding: 0px;
}

.ts-right-panel-body-content {
	margin-top: 20px;
}

.ts-right-panel-body-content.sl {
	margin-top: 20px;
	float: right;
	width: calc(100% - 70px);
}

.ts-right-panel-header-times {
	height: 48px;
	line-height: 48px;
	text-align: center;
}

.ts-right-panel-sl-container {
	float: left;
	font-size: var(--font-size-xl);
	position: relative;
	top: 0;
	z-index: 11;
	width: var(--right-panel-header-height);
	border: transparent;
	margin-right: 10px;
	margin-left: 8px;
	text-align: center;
}

.ts-right-panel-sl-container .ts-righ-panel-btn-vertical {
	color: var(--primary-color-dark);
}

.ts-right-panel-sl-btn > .ant-btn {
	z-index: 11;
	top: 0;
	height: 100%;
	width: var(--right-panel-header-height);
}

.ts-button.ts-right-panel-sl-btn > .ant-btn {
	border: none;
	border-right: solid 1px var(--btn-color-disabled);
	background-color: var(--pale-grey);
	position: fixed;
	display: inherit;
	top: var(--right-panel-header-height);
}

.ts-button.ts-right-panel-sl-btn > button.ant-btn > div {
	width: 100%;
	top: calc(20px + var(--right-panel-header-height));
	position: absolute;
}

.ts-right-panel-sl-container> .ts-button {
	display: block;
}

.ts-button .ts-right-panel-form-btn.open {
	box-shadow: 9px -3px 0 0 var(--white);
}

.ts-button .ts-right-panel-lookup-btn.open {
	box-shadow: 9px 0 0 0 var(--white);
}

.ts-button .ts-right-panel-error-btn.open {
	box-shadow: 9px 0 0 0 var(--white);
}

.ts-right-panel-form-btn-separator,
.ts-right-panel-lookup-btn-separator {
	transform: translatey(1px);
	z-index: 11;
	position: absolute;
	border-bottom: solid 1px var(--btn-color-disabled);
	margin-left: calc(calc(100% - 32px) / 2);
	height: 1px;
	width: 32px;
}

.ts-right-panel-form-btn-separator.open {
	border-bottom: solid 3px var(--primary-color);
	transform: translatey(-3px);
	height: 3px;
	width: var(--right-panel-header-height);
	margin-left: 0px;
}

.ts-right-panel-lookup-btn-separator.open.lookupenabled,
.ts-right-panel-lookup-btn-separator.open.errorsenabled {
	display: none;
}

.ts-righ-panel-btn-vertical {
	transform: rotate(90deg) translatey(22px);
}

.ts-right-panel-body-secondLevel {
	padding: 20px;
}

.ts-right-panel-body-firstLevel {
	padding: 20px 30px 20px 30px;
}

.ts-rihgt-panel-icon-sl-container {
	height: 40px;
	width: 40px;
	top: 20px;
	position: absolute;
	text-align: center;
}

.ts-right-panel-icon-sl {
	position: fixed;
	z-index: 12;
	color: var(--primary-color-dark);
}

.ts-right-panel-btn-container.open > .active {
	background-image: var(--primary-background);
}

.ts-right-panel-btn-container.open > .active .ts-button > .ant-btn {
	color: var(--white);
	background-color: transparent;
	box-shadow: none;
}