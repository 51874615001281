/* .ts-collapse .ant-collapse-content > .ant-collapse-content-box {
	padding-left: 0;
	padding-right: 0;
}

.ts-collapse .ant-collapse-header:focus{
	border: 1px solid var(--primary-color);
}

.ts-collapse .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
	padding-left: 40px;
} */

.ts-collapse .ant-collapse-content > .ant-collapse-content-box {
	padding-left: 40px;
}

/* per visualizzazione dello switch nell'extra del header */

.ts-collapse .ant-collapse-header .ts-switch {
	height: auto;
}

.ts-collapse .ant-collapse-header .ts-field-wrapper {
	padding: 0;
	margin: 0;
}