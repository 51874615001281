/* Search */

.ts-search {
	height: var(--normal-height);
	width: 100%;
	background-color: var(--input-backgroud);
	color: var(--input-value);
	position: relative;
}

.ts-search .ts-search-wrapper,
.ts-search .ts-search-wrapper .ts-search-autocomplete,
.ts-search .ts-search-wrapper .ts-search-autocomplete * {
	height: 100%;
	width: 100%;
}

.ts-search .ts-search-wrapper .ts-search-autocomplete .ant-select-selection {
	border-radius: initial;
	background-color: var(--input-backgroud);
}

.ts-search
.ts-search-wrapper
.ts-search-autocomplete
.ant-select-selection__rendered {
	margin: 0;
	line-height: var(--input-line-height);
}

.ts-search
.ts-search-wrapper
.ant-select-search--inline
.ant-select-search__field {
	padding-right: 40px;
	padding-left: 16px;
	position: absolute;
	display: block;
}

.ts-search .ts-search-wrapper .ant-select-search .ant-select-search--inline,
.ts-search
.ts-search-wrapper
.ant-select-search
.ant-select-search--inline
> :first-child {
	position: absolute;
}

.ts-search .ts-search-wrapper .ts-search-btn {
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	display: table;
	height: var(--normal-height);
}

.ts-search .ts-search-wrapper .ts-search-btn > button,
.ts-search .ts-search-wrapper .ts-search-btn > button:hover,
.ts-search .ts-search-wrapper .ts-search-btn > button:focus {
	display: table-cell;
	vertical-align: middle;
	height: 100%;
	border: 0;
	font-size: var(--large-font-size);
	background: transparent;
	animation: none;
}

.ts-search .ts-search-wrapper .ts-search-btn > button:after,
.ts-search .ts-search-wrapper .ts-search-btn > button:before {
	animation: none;
}

.ts-search .ts-search-wrapper .ant-select-focused .ant-select-selection {
	box-shadow: none;
}

.ts-search .ant-select-focused .ant-select-selection,
.ts-search .ant-select-search--inline .ant-select-search__field:focus {
	/* color: var(--primary-color); */
	background-color: var(--input-backgroud);
}

.ts-search .ant-input:focus {
	border-color: var(--primary-color);
}

/* TsSearch option */

.ts-search-option {
	height: 100%;
	width: 100%;
	display: table;
	padding-right: 40px;
	padding-left: 16px;
}

.ts-search-option > *:first-child {
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}

.ts-search-option .ts-search-autocomplete-icon {
	position: absolute;
	right: 0;
	height: 100%;
	width: 40px;
	display: table;
}

.ts-search-option .ts-search-autocomplete-icon > *:first-child {
	right: 0;
	height: 100%;
	width: 100%;
	display: table-cell;
	vertical-align: middle;
}

.ts-search-dropdown .ant-select-dropdown-menu-item {
	line-height: var(--input-line-height);
	padding: 0;
	height: var(--normal-height);
}

.ts-search-dropdown .ant-select-dropdown-menu-item:hover {
	background-color: var(--input-backgroud-hover);
	color: var(--primary-color);
}

.ts-search-dropdown .ant-select-dropdown-menu-item:first-child,
.ts-search-dropdown .ant-select-dropdown-menu-item:last-child {
	border-radius: initial;
}
