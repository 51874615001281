.ts-dropdown {
	display: inline-block;
	position: relative;
	width: auto;
	text-align: center;
	height: 32px;
}

.ts-dropdown .ts-dropdown-button {
	display: flex;
}

.ts-dropdown .ts-dropdown-button,
.ts-dropdown .ts-dropdown-button> input {
	width: auto;
	color: var(--title-color);
	border: solid 1px var(--title-color);
	background-color: var(--white);
	border-radius: 2px;
	height: 32px;
}

.ts-dropdown .ts-dropdown-button> input {
	display: inline-block;
	padding: 0 0 0 15px;
	background-color: transparent;
	border: none;
	height: 100%;
}

.ts-dropdown .ts-dropdown-button:hover,
.ts-dropdown .ts-dropdown-button:hover> input {
	cursor: pointer;
	color: var(--white);
	letter-spacing: 0;
	text-align: center;
	background-color: var(--cerulean);
	background-image: inherit;
}

.ts-dropdown .ts-dropdown-button.disabled,
.ts-dropdown .ts-dropdown-button.disabled> input {
	border-radius: 2px;
	background-color: var(--white);
	border-color: var(--columbia-blue);
	color: var(--columbia-blue);
	cursor: default;
}

.ts-dropdown .ts-dropdown-button.disabled,
.ts-dropdown .ts-dropdown-button.disabled> input,
.ts-dropdown .ts-dropdown-button.disabled> div> i {
	cursor: default;
}

.ts-dropdown .ts-dropdown-button.primary {
	border: transparent;
	background-image: var(--primary-background);
}

.ts-dropdown .ts-dropdown-button.primary> input {
	background-image: none;
	border: transparent;
	text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
	color: var(--white);
}

.ts-dropdown .ts-dropdown-button.primary:hover,
.ts-dropdown .ts-dropdown-button.primary:hover> input {
	color: var(--white);
	letter-spacing: 0;
	text-align: center;
	text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
	background-color: var(--cyan-progress);
	background-image: inherit;
}

.ts-dropdown .ts-dropdown-button.primary.disabled,
.ts-dropdown .ts-dropdown-button.primary.disabled> input {
	color: var(--white);
	letter-spacing: 0;
	text-align: center;
	text-shadow: none;
	background-color: var(--columbia-blue);
	background-image: inherit;
}

.ts-dropdown .ts-dropdown-button.primarydanger,
.ts-dropdown .ts-dropdown-button.primarydanger> input {
	color: var(--white);
	border-color: var(--maximum-red);
	background-color: var(--maximum-red);
}

.ts-dropdown .ts-dropdown-button.primarydanger:hover,
.ts-dropdown .ts-dropdown-button.primarydanger:hover> input {
	color: var(--white);
	letter-spacing: 0;
	text-align: center;
	text-shadow: 1px 1px 0 rgba(21, 41, 53, 0.24);
	background-color: var(--ogre-odor);
	background-image: inherit;
}

.ts-dropdown .ts-dropdown-button.primarydanger.disabled,
.ts-dropdown .ts-dropdown-button.primarydanger.disabled> input {
	color: var(--white);
	letter-spacing: 0;
	text-align: center;
	text-shadow: none;
	border: none;
	background-color: var(--gainsboro);
	background-image: inherit;
}

.ts-dropdown .ts-dropdown-button.danger,
.ts-dropdown .ts-dropdown-button.danger> input {
	color: var(--maximum-red);
	border-color: var(--maximum-red);
	background-color: var(--white);
}

.ts-dropdown .ts-dropdown-button.danger.disabled,
.ts-dropdown .ts-dropdown-button.danger.disabled> input {
	border-radius: 2px;
	background-color: var(--white);
	border-color: var(--gainsboro);
	color: var(--gainsboro);
	cursor: default;
}


.ts-dropdown .ts-dropdown-menu {
	position: absolute;
	display: flex;
	background: var(--white);
	height: auto;
	width: inherit;
	color: var(--yankees-blue);
	text-align: left;
	padding: 0px;
	margin: 0%;
	box-shadow: 0 2px 8px 0 rgba(21, 41, 53, 0.32);
	z-index: 100;
}

.ts-dropdown .ts-dropdown-menu> ul {
	list-style-type: none;
	padding: 0px;
	margin: 0px;
	width: 100%;
}

.ts-dropdown .ts-dropdown-menu-item {
	height: 32px;
	position: relative;
	cursor: pointer;
	padding: 0 16px 0 16px;
	display: block;
}

.ts-dropdown .ts-dropdown-menu-item> span {
	display: inline-block;
	vertical-align: middle;
}

.ts-dropdown .ts-dropdown-menu-item::before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
}

.ts-dropdown .ts-dropdown-menu-item.disabled {
	color: var(--columbia-blue);
	cursor: default;
}

.ts-dropdown .ts-dropdown-menu.danger> ul> .ts-dropdown-menu-item:hover,
.ts-dropdown .ts-dropdown-menu.primarydanger> ul> .ts-dropdown-menu-item:hover {
	border: solid 1px var(--maximum-red);
}

.ts-dropdown .ts-dropdown-menu-item:hover {
	border: solid 1px var(--title-color);
}

.ts-dropdown .ts-dropdown-button> div {
	display: inline-block;
}

.ts-dropdown .ts-dropdown-button> div:last-child {
	width: 40px;
}

.ts-dropdown .ts-dropdown-button> input:first-child {
	width: calc(100% - 40px);
}

.ts-dropdown .ts-dropdown-icon-down {
	width: 40px;
	height: 32px;
	text-shadow: none;
	padding: 7px 0 10px 0;
}

.ts-dropdown .ts-dropdown-button.primary> .ts-dropdown-icon-down {
	color: var(--white);
}

.ts-dropdown .ts-dropdown-button.primarydanger> .ts-dropdown-icon-down {
	color: var(--white);
}