.ts-stacklayout {
	width: 100%;
	height: 100%;
	padding: 8px;
	position: relative;
}

.ts-stacklayout .ts-stacklayout-horizontal {
	min-height: var(--normal-height);
	width: 100%;
}

.ts-stacklayout .ts-stacklayout-vertical {
	min-width: var(--normal-height);
	height: 100%;
}

.ts-stacklayout .ts-stacklayout-lr {
	position: absolute;
	top: 0;
	left: 0;
}

.ts-stacklayout .ts-stacklayout-rl {
	position: absolute;
	top: 0;
	right: 0;
}

.ts-stacklayout .ts-stacklayout-td {
	position: absolute;
	top: 0;
	left: 0;
}

.ts-stacklayout .ts-stacklayout-bu {
	position: absolute;
	bottom: 0;
	left: 0;
}

.ts-stacklayout .ts-stacklayout-item {
	padding: 8px;
}

.ts-stacklayout .ts-stacklayout-lr .ts-stacklayout-item {
	float: left;
}

.ts-stacklayout .ts-stacklayout-rl .ts-stacklayout-item {
	float: right;
}

.ts-stacklayout .ts-stacklayout-td .ts-stacklayout-item,
.ts-stacklayout .ts-stacklayout-bu .ts-stacklayout-item {
	width: 100%;
}