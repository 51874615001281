.ts-radiogroup * {
	outline: none !important;
}

.ts-radiogroup .ant-radio-button-wrapper {
	font-family: Roboto-Medium, Roboto Medium, Roboto;
	font-weight: 400;
	font-style: normal;
	text-align: center;
	border: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: var(--cadet);
	background-color: var(--gainsboro);
	padding: 11px 12px;
	height: auto;
	line-height: 18px;
	min-width: 124px;
	border: 1px solid var(--gainsboro);
}

.ts-radiogroup .ant-radio-wrapper {
	font-family: Roboto-Medium, Roboto Medium, Roboto;
	font-weight: 400;
	font-style: normal;
	text-align: left;
	border: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: var(--cadet);
	height: auto;
	min-width: 124px;
	margin-right: 24px;	
}

.ts-radiogroup .ant-radio-button-wrapper-checked {
	background-color: var(--diamond);
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	font-family: Roboto-Medium, Roboto Medium, Roboto;
	font-weight: 400;
	font-style: normal;
	color: var(--dark-imperial-blue);
	text-align: center;
	border: 1px solid var(--diamond);
}

.ts-radiogroup .ant-radio-wrapper-checked {
	border: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	font-family: Roboto-Medium, Roboto Medium, Roboto;
	font-weight: 400;
	font-style: normal;
	color: var(--cadet);
	text-align: left;
	margin-right: 24px;
}

.ts-radiogroup .ant-radio-group-large .ant-radio-button-wrapper {
	font-size: var(--font-size-lg);
}

.ts-radiogroup .ant-radio-button-wrapper:not(:first-child)::before {
	background-color: transparent;
}

.ts-radiogroup .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover,
.ts-radiogroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
	border: 1px solid var(--cerulean);
}

.ts-radiogroup .ant-radio-button-wrapper:first-child {
	border-radius: 30px 0 0 30px;
}

.ts-radiogroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
	border: 1px solid var(--cerulean);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.ts-radiogroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child:not(:hover) {
	border: 1px solid var(--diamond);
}

.ts-radiogroup .ant-radio-button-wrapper:last-child {
	border-radius: 0 30px 30px 0;
}

.ts-radiogroup .ant-radio-inner,
.ts-radiogroup .ant-radio-checked .ant-radio-inner,
.ts-radiogroup .ant-radio-inner::after {
	background-color: var(--diamond);
}

.ts-radiogroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	color: var(--dark-imperial-blue);
	border: 1px solid var(--title-color);
}

.ts-radiogroup .ant-radio-wrapper-disabled > span {
	color: var(--quick-silver);
	cursor: default;
}

.ts-radiogroup .ant-radio-wrapper-disabled,
.ts-radiogroup .ant-radio-wrapper-disabled .ant-radio-inner {
	cursor: default;
	background-color: var(--white);
}

.ts-radiogroup .ant-radio-wrapper-disabled .ant-radio-inner {
	border-color: var(--columbia-blue) !important;
}

.ts-radiogroup .ant-radio-wrapper-disabled > span > input {
	cursor: default;
}

.ts-radiogroup .ts-radio-vertical {
	display: block;
	height: 24px;
	line-height: 24px;
	margin-bottom: 16px;
}

.ts-radiogroup .ant-radio-checked .ant-radio-inner::after {
	transform: scale(1);
}