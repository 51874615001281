.ts-uploader {
	min-width: 280px;
}

.ts-uploader .ts-uploader-header {
	text-align: left;
}

.ts-uploader .ts-uploader-label {
	font-family: var(--font-family);
	font-size: var(--normal-font-size);
	color: var(--yankees-blue);
	font-weight: 500;
	line-height: 18px;
}

.ts-uploader .ts-uploader-textinfo {
	font-family: var(--font-family);
	font-size: var(--small-font-size);
	color: var(--quick-silver);
	font-weight: 400;
	line-height: 18px;
}

.ts-uploader .ts-uploader-button {
	cursor: default;
	pointer-events: auto;
	padding: 0 10px 0 10px;
}

.ts-uploader .ant-upload .ant-upload-btn {
	cursor: default;
}

.ts-uploader .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled),
.ts-uploader .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
	border-color: var(--cerulean);
}

.ts-uploader .ant-upload.ant-upload-drag {
	border-color: var(--cerulean);
}

.ts-uploader .ant-upload.ant-upload-drag.ant-upload-disabled {
	border-color: var(--input-bordercolor-disabled);
}

.ts-uploader .ts-uploader-text {
	font-family: var(--font-family);
	font-size: var(--large-font-size);
	color: var(--cerulean);
	font-weight: 500px;
}

.ts-uploader .ts-uploader-text-disabled {
	font-family: var(--font-family);
	font-size: var(--large-font-size);
	color: var(--label-color-disabled);
	font-weight: 500px;
}

.ts-uploader .ts-uploader-center {
	height: 32px;
	flex: auto;
	display: inline-flex;
	align-items: center;
}

.ts-uploader> .ant-upload-list-item-error,
.ts-uploader> .ant-upload-list-item-error .anticon-paper-clip,
.ts-uploader> .ant-upload-list-item-error .ant-upload-list-item-name {
	color: var(--yankees-blue);
}

.ts-uploader .ts-uploader .anticon-paper-clip {
	display: none;
}

.ts-uploader .ant-upload-list-item-name {
	padding-left: 0px;
}

.ts-uploader .ant-upload-list-item,
.ts-uploader .ant-upload-list-item-info {
	display: none;
}

.ts-uploader .ts-uploader-filelist-header {
	height: 60px;
	display: flex;
	align-items: center;
	padding-left: 8px;
	border-bottom: 1px solid rgba(21, 41, 53, 0.24);
}

.ts-uploader .ts-uploader-filelist-header-label {
	font-family: var(--font-family);
	font-size: var(--large-font-size);
	color: var(--yankees-blue);
	font-weight: 500;
	line-height: 18px;
}

.ts-uploader .ts-uploader-filelist-group {
	max-height: 200px;
	overflow: scroll;
}

.ts-uploader .ts-uploader-filelist {
	height: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid rgba(21, 41, 53, 0.24);
	flex-flow: wrap;
}

.ts-uploader .ts-uploader-filename {
	font-family: var(--font-family);
	font-size: var(--small-font-size);
	color: var(--yankees-blue);
	font-weight: 400;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding-right: 5px;
	padding-left: 8px;
	display: block;
	text-align: initial;
}

.ts-uploader .ts-uploader-progress-group {
	display: flex;
	width: calc(40% - 10px);
	justify-content: flex-end;
	padding-right: 8px;
}

.ts-uploader .ts-uploader-progress {
	width: calc(90% - 30px);
	text-align: end;
	padding-right: 14px;
}

.ts-uploader .ts-uploader-progress .ant-progress-text {
	display: none;
}

.ts-uploader .ts-uploader-progress .ant-progress-inner {
	border-radius: 0px;
	height: 5px;
}

.ts-uploader .ts-uploader-progress .ant-progress-success-bg,
.ts-uploader .ts-uploader-progress .ant-progress-bg {
	background-image: var(--primary-background);
	border-radius: 0px;
}

.ts-uploader .ts-uploader-progress .ant-progress-show-info .ant-progress-outer {
	margin-right: 0px;
	padding-right: 0px;
}

.ts-uploader .ts-uploader-perc {
	width: 20px;
	text-align: end;
	display: contents;
}

.ts-uploader .ts-uploader-file-icons {
	width: 20px;
	text-align: center;
}

.ts-uploader .ts-uploader-moregroup {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ts-uploader .ts-uploader-filename .ts-tooltip,
.ts-uploader .ts-uploader-filename-label {
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

.ts-uploader-tooltip-filename {
	overflow: hidden;
	text-overflow: ellipsis;
}

.ts-uploader-tooltip-filename-hidden.ant-popover {
	display: none;
}

.ts-uploader-tooltip-filename .ant-popover-inner-content {
	background-color: var(--primary-color-dark);
	color: var(--white);
	border-radius: 2px;
	border: none;
	font-family: var(--font-family);
	font-size: var(--small-font-size);
	font-weight: 500;
	padding: 5px 5px;
}

.ts-uploader-tooltip-filename.ant-popover-placement-top> .ant-popover-content> .ant-popover-arrow,
.ts-uploader-tooltip-filename.ant-popover-placement-topLeft> .ant-popover-content> .ant-popover-arrow,
.ts-uploader-tooltip-filename.ant-popover-placement-topRight> .ant-popover-content> .ant-popover-arrow {
	background: var(--primary-color-dark);
	border: none;
	border-top-color: var(--primary-color-dark);
	border-right-color: var(--primary-color-dark);
	border-bottom-color: var(--primary-color-dark);
	border-left-color: var(--primary-color-dark);
}

.ts-uploader-moregroup-button {
	width: calc(25% - 0px);
}

.ts-uploader-removeall {
	width: calc(75% - 0px);
	display: block;
	text-align: end;
	padding-right: 5px;
}

.ts-uploader-removeall-label {
	color: var(--maximum-red);
}