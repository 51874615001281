/* input base style */

.ts-input > .ant-input,
.ts-input .ant-input-affix-wrapper > input,
.ts-input .ant-input-wrapper.ant-input-group > input
 {
	line-height: var(--input-line-height);
	height: var(--input-height);
	font-size: var(--normal-font-size);
	background: inherit;
	background-color: var(--input-backgroud);
	box-sizing: border-box;
	border-width: var(--input-border-width);
	border-style: var(--input-border-style);
	border-radius: var(--input-border-radius);	
	border-color: var(--input-bordercolor);  
	color: var(--input-color);
}

.ts-input input::placeholder {
	color: var(--gainsboro);
}

/* input focus */

.ts-input > input:focus,
.ts-input .ant-input-affix-wrapper > input:focus,
.ts-input .ant-input-wrapper.ant-input-group > input:focus {
	box-shadow: 1px 1px transparent;  /* To remove natural antd shadow */
	border-color: var(--input-bordercolor-active);  
	background-color: var(--input-backgroud);
}

/* input hover */

.ts-input > .ant-input:not(:disabled):hover,
.ts-input .ant-input-affix-wrapper > input:not(:disabled):hover,
.ts-input .ant-input-wrapper.ant-input-group > input:not(:disabled):hover {
	box-shadow: 1px 1px transparent; /* To remove natural antd shadow */
	border-color: var(--input-bordercolor-active);  
	background-color: var(--input-backgroud);
}

/* addon before & after */

.ts-input .ant-input-wrapper.ant-input-group .ant-input-group-addon {
	border-radius: var(--input-border-radius);
	border-width: var(--input-border-width);
	border-color: var(--input-bordercolor);
	background-color: var(--input-addon-backgroud);
	color: var(--input-addoncolor);
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;  
}


/* prefix & suffix */

.ts-input .ant-input-affix-wrapper .ant-input:not(:first-child) {
	padding-left: 40px;
}

.ts-input .ant-input-affix-wrapper .ant-input:not(:last-child) {
	padding-right: 40px;
}

/* disabled */

.ts-input .ant-input.ant-input-disabled,
/* disabled lookup */
.ts-input .ant-input-wrapper.ant-input-group > input:disabled {
	border-color: var(--input-bordercolor-disabled);    
	background-color: var(--input-disabled-backgroud);
	color: var(--input-color-disabled);
	opacity: var(--input-opacity-disabled);
}

/* Antd Fix */

form .ts-input :not(.ant-input-group-wrapper) > .ant-input-group, form .ts-input .ant-input-group-wrapper {
	top: 0;
}


/* readonly */

.ts-input-readonly input.ant-input,
.ts-input-readonly input.ant-input:hover {
	border: 1px solid var(--silver-sand);
	line-height: var(--input-line-height);
	height: var(--input-height);
	background-color: var(--ghost-white);
	border-radius: 0;
}

.ts-input-readonly span.ant-input-group-addon {
	display: none;
}

.ts-input-readonly input.ant-input.ant-input-disabled {
	background-color: var(--ghost-white);
	cursor: default;
	color: var(--yankees-blue);
}