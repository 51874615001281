/* Fab */

.ts-fab {
	z-index: 250;
}

.ts-fab-box {
	height: 0;
	display: flex;
}

.ts-fab .ant-btn,
.ts-fab .ant-btn:active,
.ts-fab .ant-btn:hover,
.ts-fab .ant-btn:focus {
	box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.3);
	position: fixed;
}

.ts-fab.bottom-right .ant-btn {
	right: 70px;
	bottom: 70px;
}

.ts-fab.top-right .ant-btn {
	right: 70px;
	top: 70px;
}

.ts-fab.top-left .ant-btn {
	left: 70px;
	top: 70px;
}

.ts-fab.bottom-left .ant-btn {
	left: 70px;
	bottom: 70px;
}

.ts-fab .ant-btn-circle, 
.ts-fab .ant-btn-circle-outline {
	height: var(--normal-height);
	width: var(--normal-height);
	font-size: var(--x-large-font-size);
}

.ts-fab .ant-btn {
	height: var(--normal-height);
	font-size: var(--x-large-font-size);
}

.ts-fab .ant-btn-circle.ant-btn-sm, 
.ts-fab .ant-btn-circle-outline.ant-btn-sm {
	height: var(--small-height);
	width: var(--small-height);
	font-size: var(--small-font-size);
}

.ts-fab .ant-btn.ant-btn-sm {
	height: var(--small-height);
	font-size: var(--small-font-size);
}

.ts-fab .ant-btn-circle.ant-btn-lg, 
.ts-fab .ant-btn-circle-outline.ant-btn-lg {
	height: var(--large-height);
	width: var(--large-height);
	font-size: var(--xxxx-large-font-size);
}

.ts-fab .ant-btn.ant-btn-lg {
	height: var(--large-height);
	font-size: var(--xxxx-large-font-size);
}
