.ts-modal .ant-modal-title {
	font-family: Cairo;
	font-style: normal;
	font-size: 21px;
	color: #35383a;
	padding-top: 10px;
} 

.ts-modal .ant-modal-confirm-title {
	margin: 0px;
	padding: 25px 50px;
	border-bottom: 2px solid var(--cerulean);
	background-color: var(--white);
	font-family: Cairo;
	font-size: 24px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.1;
	letter-spacing: normal;
	color: var(--dark-imperial-blue);
}

.ts-modal-danger .ant-modal-confirm-title {
	border-bottom: 2px solid var(--danger-color);
}

.ts-modal .ant-modal-confirm-content {
	white-space: pre-line;
	margin: 0px !important;
	padding: 25px 50px;
	background-color: var(--white);
	font-size: 16px;
	line-height: 1.5;
}
 
.ts-modal .ant-modal-content{
	width: 110%;
}
 
.ts-modal .ant-modal-body {
	padding: 0px !important; 
}
 
.ts-modal .anticon svg {
	display: none;
}
 
.ts-modal .ant-modal-confirm-body-wrapper{
	background-color: var(--white-smoke);
}
 
.ts-modal .ant-modal-confirm-btns {
	margin: 8px !important;
}
 
.ts-modal .ant-modal-confirm .ant-modal-confirm-btns button + button {
	width: 100px;
	height: 32px;
}
 
.ts-modal .ant-modal-confirm .ant-modal-confirm-btns button {
	width: 100px;
	height: 32px;
}

.ant-modal-root .ant-modal-wrap,
.ant-modal-root .ant-modal-mask {
	z-index: 3000;
}
